import { ocrResponseToOCRTextContent, runOcrFromFile } from './lib/ocr';
import { AbstractOCRAdapter, OCRResult } from '@speechifyinc/multiplatform-sdk/api/adapters/ocr';
import { Callback } from './lib/typeAliases';
import { Viewport } from '@speechifyinc/multiplatform-sdk/api/util/images';
import { BinaryContentReadableRandomly, BinaryContentWithMimeTypeFromNativeReadableInChunks } from '@speechifyinc/multiplatform-sdk/api/util/io';
import { callbackFromAsync } from './lib/callbackFromAsync';

export class WebOcrAdapter extends AbstractOCRAdapter {
  constructor() {
    super(/*maxConcurrency: */ 1);
  }

  runOCR = (binaryContent: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>, callback: Callback<OCRResult | null>) =>
    callbackFromAsync(async () => {
      const data = await binaryContent.binaryContent.blob.arrayBuffer();
      const ocrResponse = await runOcrFromFile(data);

      if (ocrResponse === null) return null;

      const fullTextAnnotation = ocrResponse.fullTextAnnotation;
      const page = fullTextAnnotation.pages[0];

      return new OCRResult(
        /* rawText: */ fullTextAnnotation.text,
        /*textContent: */ ocrResponseToOCRTextContent(ocrResponse),
        new Viewport(page.width, page.height)
      );
    }, callback);
}
