import assert from 'assert';
import { DEFAULT_PDF_INITIAL_ZOOM } from 'lib/pdf/pspdfkit';
import { createLocalStorageStore } from 'lib/zustand/store';
import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { PDFOverlayInfo } from 'modules/sdk/lib';
import { ListeningDependencies } from 'modules/sdk/listeningDependencies';
import { AUTOSCROLL_LOCAL_STORAGE_KEY } from 'store/preferences';

import { autoScrollStoreActions } from '../autoScroll/autoScrollStore';
import { clickToListenStoreActions } from '../clickToListen/clickToListenStore';
import { highlightingStoreActions } from '../highlighting/highlightingStore';
import { toggleSettingImplementationMapping } from './implementation';
import {
  enforcePremiumConstraintForSkipContentSettings,
  getSkipContentSettingsWithAllValueSetAs,
  setAllSkipSettingsValueTo,
  updateSkipSettings
} from './implementation/skipContent';
import { onZoomChange } from './implementation/zoom';
import {
  BooleanSettingKey,
  DEFAULT_DISPLAY_SETTINGS,
  DEFAULT_SKIP_CONTENT_SETTINGS,
  DEFAULT_ZOOM_PERCENTAGE,
  ListeningSettings,
  SkipContentSettings
} from './settings';

type ListeningSettingsState = ListeningSettings;
type PersistedListeningSettingsState = Omit<ListeningSettingsState, 'zoomPercentage'>;

export const useListeningSettingsStore = createLocalStorageStore<ListeningSettingsState, PersistedListeningSettingsState>(
  () => {
    return {
      ...DEFAULT_DISPLAY_SETTINGS,
      zoomPercentage: DEFAULT_ZOOM_PERCENTAGE,
      headers: true,
      footers: true,
      footnotes: true,
      braces: true,
      citations: true,
      parentheses: true,
      brackets: true,
      urls: true
    };
  },
  {
    storageName: 'speechifyListeningSettings',
    version: 1,
    partialize: (state: ListeningSettingsState) => {
      const { zoomPercentage, ...persistedState } = state;
      return persistedState;
    },
    backfillStateFromReduxPersist(reduxPersistedState, defaultState) {
      return {
        ...defaultState,
        darkMode: reduxPersistedState.darkMode.isDarkMode,
        autoScroll: (localStorage.getItem(AUTOSCROLL_LOCAL_STORAGE_KEY) ?? 'true') === 'true'
      };
    }
  }
);

export const listeningSettingsStoreSelectors = {
  getSkipContentSettings: (state?: ListeningSettingsState): SkipContentSettings => {
    state ??= useListeningSettingsStore.getState();
    const skipContentSettings: SkipContentSettings = {} as SkipContentSettings;

    for (const key of Object.keys(DEFAULT_SKIP_CONTENT_SETTINGS) as (keyof SkipContentSettings)[]) {
      skipContentSettings[key] = state[key];
    }

    return skipContentSettings;
  }
};

const _settingImpl = async (setting: BooleanSettingKey, value: boolean) => {
  const toggleImpl = toggleSettingImplementationMapping[setting];
  assert(toggleImpl, `No implementation for setting ${setting}`);
  toggleImpl(value);
};

const toggleSetting = async (setting: BooleanSettingKey) => {
  const currentValue = useListeningSettingsStore.getState()[setting];
  const newValue = !currentValue;

  useListeningSettingsStore.setState(state => ({ ...state, [setting]: newValue }));
  const toggleImpl = toggleSettingImplementationMapping[setting];
  assert(toggleImpl, `No implementation for setting ${setting}`);
  toggleImpl(newValue);

  logAnalyticsEvent(AnalyticsEventKey.listeningSettingsClicked, {
    key: setting,
    value: newValue
  });
};

const updateAllSkipSettings = (value: boolean) => {
  const skipSettingsNewValue = getSkipContentSettingsWithAllValueSetAs(value);
  useListeningSettingsStore.setState(state => ({ ...state, ...skipSettingsNewValue }));
  setAllSkipSettingsValueTo(value);
};

const initializeListeningSettingsStore = async (listeningDependencies: ListeningDependencies) => {
  await useListeningSettingsStore.waitForInitialHydration();
  const latestState = useListeningSettingsStore.getState();

  await Promise.all([
    highlightingStoreActions.initializeHighlighting(listeningDependencies),
    autoScrollStoreActions.initializeAutoScroll(listeningDependencies),
    clickToListenStoreActions.initializeClickToListen(listeningDependencies)
  ]);

  const defaultZoom = listeningDependencies.overlayInfo instanceof PDFOverlayInfo ? DEFAULT_PDF_INITIAL_ZOOM * 100 : DEFAULT_ZOOM_PERCENTAGE;
  useListeningSettingsStore.setState(state => ({
    ...state,
    zoomPercentage: defaultZoom
  }));

  // Enabled skip content is only for premium users, so during hydration we should enforce this constraint again.
  // We can't do enforcement on setter level because skip content can be enabled for the 1st doc for free users, and we don't have easy access to get the context at the moment.
  const skipContentSettings = enforcePremiumConstraintForSkipContentSettings(listeningSettingsStoreSelectors.getSkipContentSettings(latestState));
  updateSkipSettings(skipContentSettings);
  useListeningSettingsStore.setState(state => ({ ...state, ...skipContentSettings }));

  // Apply settings
  for (const [setting, value] of Object.entries(latestState)) {
    if (setting in skipContentSettings) {
      continue;
    }
    if (value && toggleSettingImplementationMapping[setting as BooleanSettingKey]) {
      _settingImpl(setting as BooleanSettingKey, value as boolean);
    }
  }
};

if (typeof window !== 'undefined') {
  useListeningSettingsStore.waitForInitialHydration().then(() => {
    if (useListeningSettingsStore.getState().darkMode) {
      _settingImpl('darkMode', true);
    }
  });
}

export const listeningSettingsStoreActions = {
  initializeListeningSettingsStore,
  toggleSetting,
  onZoomChange,
  updateAllSkipSettings
};
