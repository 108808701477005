import { defaultSkipSettings, SkipSettings } from 'components/item/advancedListening/AdvancedListeningSettings';
import { OcrFallbackStrategyVariant } from 'constants/featureDefinitions';
import { Auth, onAuthStateChanged } from 'firebase/auth';
import { FeatureNameEnum, getFeatureFlag, getFeatureVariant } from 'hooks/useFeatureFlags';
import { memoizeAsync } from 'lib/cache/inMemory';
import { instrumentAction, logError } from 'lib/observability';
import * as speechify from 'lib/speechify';
import { promisify } from 'lib/speechify/adaptors/promisify';
import {
  generateVoiceSpec,
  getAllVoicesFromList,
  getClientVoices,
  getDefaultVoiceList,
  mapVoiceSpecOfAvailableVoiceToVoiceDefinition,
  mapVoiceToDefinition,
  VoiceDefinition
} from 'lib/speechify/voices';
import { BASE_FACTOR_SPEED_WPM } from 'modules/speed/utils/constants';
import { RootState, store } from 'store';
import { getInitialVoice } from 'store/auth';
import { isSkipHeaderFooterUpsellEnabled } from 'store/reader/helpers';
import { getLocalPreference } from 'utils/preferences';

import {
  AudioConfig,
  AudioMediaFormat,
  BookReadingBundlerConfig,
  BookReadingBundlerOptions,
  BundlerFactoryConfig,
  ClassicReadingBundlerConfig,
  ClassicReadingBundlerOptions,
  ContentBundle,
  ContentBundlerConfig,
  ContentBundlerOptions,
  EmbeddedReadingBundlerConfig,
  EmbeddedReadingBundlerOptions,
  ImportOptions,
  ImportStartChoice,
  ListeningBundlerConfig,
  ListeningBundlerOptions,
  LocalSynthesisVoice,
  OcrFallbackStrategy,
  ReadingBundle,
  SpeechifyEntityType,
  SpeechifyURI,
  VoicePreferences,
  VoicePreferenceWithStaticValue,
  VoiceSpec,
  VoiceSpecOfAvailableVoice
} from '@speechifyinc/multiplatform-sdk';
import { VoiceRef } from '@speechifyinc/multiplatform-sdk/api/adapters/localsynthesis';
import { VoiceGender } from '@speechifyinc/multiplatform-sdk/api/audio';

import { ErrorSource } from '../../../../constants/errors';
import { IRecord } from '../../../../interfaces';
import { auth, getSpeechifyClient, WebBoundaryMap } from '../../../../lib/speechify';
import { wrapWithCorsProxy } from '../../../../lib/speechify/adaptors/cors';
import { Callback } from '../../../../lib/speechify/adaptors/lib/typeAliases';
import { applyOn } from './fn';
import { BookReadingInfo, ClassicReadingInfo, EmbeddedReadingInfo, ReadingInfo } from './ReadingInfo';
import { BookReaderFromFileParams, ClassicReaderFromTextParams, ReaderFromUrlParams, ThumbnailInfo } from './types';

const CONTENT_TYPE_PDF = 'application/pdf';

export const isPDF = async (url: string) => {
  const response = await fetch(wrapWithCorsProxy(url), { method: 'HEAD' });
  return response.headers.get('Content-Type') === CONTENT_TYPE_PDF;
};

type ImportParams = {
  bundle: ReadingBundle;
  folderId: string | null;
  title: string | null;
};

const handleImport = ({ bundle, folderId = null, title }: ImportParams, onImportComplete: Callback<SpeechifyURI>) => {
  bundle.contentImporter.startImport(new ImportOptions(title, folderId, new WebBoundaryMap({ prop: 'value' })), onImportComplete);
};

function getCurrentUser(auth: Auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      user => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
}

export type VoiceDef = {
  displayName: string;
  name: string;
  flag: string;
  languageCode: string;
};

export const generateLocalVoiceSpec = (voice: VoiceDef) => new VoiceSpec.Local(voice.displayName, voice.name, getFlagFromLanguageCode(voice.languageCode));

export function findSimilarVoice(voiceToSearch: Partial<VoiceDef> & { languageCode: string }, specs: Array<VoiceDef>) {
  // default to english for now
  const voiceList = ['Samantha', 'David', 'Alex', 'Daniel', 'Karen', 'Moira'];
  const voicesByLang = specs.filter(voice => voice.languageCode.startsWith(voiceToSearch.languageCode));

  const closestVoiceDefinition =
    voiceList.map(name => voicesByLang.find(voice => voice.name?.toLowerCase().includes(name?.toLowerCase()))).filter(Boolean)[0] ??
    voicesByLang.find(voice => voiceList.some(name => voice.name?.toLowerCase().includes(name?.toLowerCase()))) ??
    voicesByLang[0];

  return closestVoiceDefinition;
}

const shouldDisableSkipSettingsForUser = async (state: RootState, itemId: string | null): Promise<boolean> => {
  const user = state.auth.user;
  if (!user || user.isAnonymous) return true;
  if (!speechify.isPremium(user) && (!(await isSkipHeaderFooterUpsellEnabled(false)) || user.firstPdfDocument !== itemId)) return true;
  return false;
};

const shouldDisableSkipHeaders = async (state: RootState, itemId: string | null): Promise<boolean> => {
  const user = state.auth.user;
  if (!user || user.isAnonymous) return true;
  if (!speechify.isPremium(user)) {
    // force skip for the non-premium first document
    // if user's firstPdfDocument is null, there is possibility that this document could be the first document with header and footer, so we allow skip
    if ((await isSkipHeaderFooterUpsellEnabled(false)) && (!user.firstPdfDocument || user.firstPdfDocument === itemId)) return false;
    return true;
  }
  const skipSettings = getLocalPreference('advancedListeningPreferences') as SkipSettings;
  const isSkipHeadersEnabled = (skipSettings || defaultSkipSettings).headers;
  return !isSkipHeadersEnabled;
};

const shouldDisableSkipFooters = async (state: RootState, itemId: string | null): Promise<boolean> => {
  const user = state.auth.user;
  if (!user || user.isAnonymous) return true;
  if (!speechify.isPremium(user)) {
    // force skip for the non-premium first document
    // if user's firstPdfDocument is null, there is possibility that this document could be the first document with header and footer, so we allow skip
    if ((await isSkipHeaderFooterUpsellEnabled(false)) && (!user.firstPdfDocument || user.firstPdfDocument === itemId)) return false;
    return true;
  }
  const skipSettings = getLocalPreference('advancedListeningPreferences') as SkipSettings;
  const isSkipFootersEnabled = (skipSettings || defaultSkipSettings).footers;
  return !isSkipFootersEnabled;
};

const shouldDisableSkipFootnotes = async (state: RootState, itemId: string | null): Promise<boolean> => {
  const user = state.auth.user;
  if (!user || user.isAnonymous) return true;
  if (!speechify.isPremium(user)) {
    // force skip for the non-premium first document
    // if user's firstPdfDocument is null, there is possibility that this document could be the first document with header and footer, so we allow skip
    if ((await isSkipHeaderFooterUpsellEnabled(false)) && (!user.firstPdfDocument || user.firstPdfDocument === itemId)) return false;
    return true;
  }
  const skipSettings = getLocalPreference('advancedListeningPreferences') as SkipSettings;
  const isSkipFootnotesEnabled = (skipSettings || defaultSkipSettings).footnotes;
  return !isSkipFootnotesEnabled;
};

const getOcrStrategy = async (isOCREnabled: boolean) => {
  if (!isOCREnabled) return OcrFallbackStrategy.Never;
  const variant = await getFeatureVariant(FeatureNameEnum.OCR_FALLBACK_STRATEGY);
  if (variant === OcrFallbackStrategyVariant.EXPERIMENTAL) return OcrFallbackStrategy.ExperimentalStrategy;
  return OcrFallbackStrategy.ConservativeLegacyStrategy;
};

export const updateBundleSkipSettings = async (readingInfo: ReadingInfo, settings: SkipSettings, itemId: string | null) => {
  const state = store.getState() as RootState;

  const skipSettings = {
    headers: false,
    footers: false,
    footnotes: false,
    citations: false,
    urls: false,
    squareBrackets: false,
    braces: false,
    roundBrackets: false
  };

  if (!(await shouldDisableSkipHeaders(state, itemId)) && settings.headers) {
    skipSettings.headers = true;
  }

  if (!(await shouldDisableSkipFooters(state, itemId)) && settings.footers) {
    skipSettings.footers = true;
  }

  if (!(await shouldDisableSkipFootnotes(state, itemId)) && settings.footnotes) {
    skipSettings.footnotes = true;
  }

  if (!(await shouldDisableSkipSettingsForUser(state, itemId))) {
    skipSettings.citations = settings.citations;
    skipSettings.urls = settings.urls;
    skipSettings.squareBrackets = settings.squareBrackets;
    skipSettings.braces = settings.braces;
    skipSettings.roundBrackets = settings.roundBrackets;
  }

  readingInfo.updateSkipSettings(skipSettings);
};

const createBundlerFactory = async (itemId: string | null = null) => {
  //  ugly hack to handle the case of when the page is reloaded and the auth has not yet been finished

  await instrumentAction(() => getCurrentUser(auth.firebaseAuth), 'getCurrentUserHack');

  const state = store.getState() as RootState;
  const initialSpeedWPM = state.auth.user?.extensionSettings?.playbackSpeed
    ? state.auth.user?.extensionSettings?.playbackSpeed * BASE_FACTOR_SPEED_WPM
    : BASE_FACTOR_SPEED_WPM;
  const voices = await getAllVoices();

  const initialVoiceFromStore = getInitialVoice(state);
  const initialVoiceInList = generateVoiceSpec(mapVoiceToDefinition((await getDefaultVoiceList()).config.fallbackVoice));

  const initialVoice = initialVoiceFromStore
    ? (voices.find(voice => voice.displayName === initialVoiceFromStore.displayName) ?? initialVoiceInList)
    : initialVoiceInList;

  // ocr premium lock feature flag
  let isOCRPremiumLocked = false;
  let isOCREnabled = true;

  if (state.auth.user && speechify.canUpgrade(state.auth.user)) {
    const variant = await getFeatureFlag(FeatureNameEnum.OCR);
    isOCRPremiumLocked = variant === 'upsell';

    if (isOCRPremiumLocked && !speechify.isPremium(state.auth.user)) {
      isOCREnabled = false;
    }
  }

  const contentBundlerOptions = new ContentBundlerOptions();

  if (!(await shouldDisableSkipHeaders(state, itemId))) {
    contentBundlerOptions.shouldSkipHeaders = true;
  } else {
    contentBundlerOptions.shouldSkipHeaders = false;
  }

  if (!(await shouldDisableSkipFooters(state, itemId))) {
    contentBundlerOptions.shouldSkipFooters = true;
  } else {
    contentBundlerOptions.shouldSkipFooters = false;
  }

  if (!(await shouldDisableSkipFootnotes(state, itemId))) {
    contentBundlerOptions.shouldSkipFootnotes = true;
  } else {
    contentBundlerOptions.shouldSkipFootnotes = false;
  }

  if (!(await shouldDisableSkipSettingsForUser(state, itemId))) {
    const skipSettings = getLocalPreference('advancedListeningPreferences') as SkipSettings;

    if (skipSettings && contentBundlerOptions.preSpeechTransformOptions) {
      contentBundlerOptions.preSpeechTransformOptions.shouldSkipCitations = skipSettings.citations;
      contentBundlerOptions.preSpeechTransformOptions.shouldSkipUrls = skipSettings.urls;
      contentBundlerOptions.preSpeechTransformOptions.shouldSkipBrackets = skipSettings.squareBrackets;
      contentBundlerOptions.preSpeechTransformOptions.shouldSkipBraces = skipSettings.braces;
      contentBundlerOptions.preSpeechTransformOptions.shouldSkipParentheses = skipSettings.roundBrackets;
    }
  }

  // VoiceSpec.Local has only subset of fields used in our code, converting it to LocalAvailable for compatibility
  let initial = initialVoice;
  if (initial instanceof VoiceSpec.Local) {
    const clientVoices = (await getClientVoices()).map(mapVoiceToDefinition);
    const voiceSpec = clientVoices.find(v => v.displayName === initial.displayName) as VoiceDefinition;
    initial = initial.createLocalAvailable(
      new LocalSynthesisVoice(
        initial.id,
        initial.displayName,
        voiceSpec.language,
        voiceSpec.gender === 'female' ? VoiceGender.FEMALE : VoiceGender.MALE,
        new VoiceRef(initialVoice)
      )
    );
  }

  const isMLPageParsingEnabled = await getFeatureVariant(FeatureNameEnum.ML_PAGE_PARSING);
  const isRichContentEnabled = await getFeatureVariant(FeatureNameEnum.RICH_CONTENT);
  const isSlidingWindowEnabled = await getFeatureVariant(FeatureNameEnum.SDK_SLIDING_WINDOW);
  const ocrStrategy = await getOcrStrategy(isOCREnabled);

  return {
    factory: getSpeechifyClient()!.createBundlerFactory(
      new BundlerFactoryConfig(
        new ClassicReadingBundlerConfig(new ClassicReadingBundlerOptions()),
        new BookReadingBundlerConfig(new BookReadingBundlerOptions(ocrStrategy, true, isMLPageParsingEnabled)),
        new EmbeddedReadingBundlerConfig(new EmbeddedReadingBundlerOptions()),
        new ListeningBundlerConfig(
          new AudioConfig(AudioMediaFormat.MP3),
          initialSpeedWPM,
          voices,
          new VoicePreferences(new VoicePreferenceWithStaticValue(initialVoice), new VoicePreferenceWithStaticValue(initialVoice)),
          applyOn(new ListeningBundlerOptions(), options => {
            // Enable Sliding Window for supported Speechify Voices
            options.textToSpeechIncludePrecedingContextForAudioSynthesisOverride = isSlidingWindowEnabled;
          })
        ),
        new ContentBundlerConfig(
          applyOn(contentBundlerOptions, options => {
            if (isRichContentEnabled) {
              options.enableRichBlocksParsingForHtmlContent();
              options.enableRichBlocksParsingForEpubContent();
            }
          })
        )
      ),
      plugins => plugins
    ),
    initialVoice: initial,
    contentBundlerOptions
  };
};

const extractThumbnailInfoFromBook = (bundle: ReadingBundle) => {
  const { bookView } = bundle.listeningBundle.contentBundle as ContentBundle.BookBundle;
  const { numberOfPages } = bookView.getMetadata();
  return { numberOfPages };
};

export const createClassicReaderFromText = async (
  params: ClassicReaderFromTextParams,
  onImportComplete: Callback<SpeechifyURI>
): Promise<ClassicReadingInfo> => {
  const { factory, initialVoice, contentBundlerOptions } = await createBundlerFactory();
  const classicReadingBundler = factory.createClassicReadingBundler();

  const bundle = await promisify(classicReadingBundler.createBundleForPlainText.bind(classicReadingBundler))(
    params.text,
    ImportStartChoice.StartImmediately,
    null
  );

  handleImport({ bundle, folderId: params.folderId || null, title: params.title }, onImportComplete);

  const thumbnail: ThumbnailInfo = {
    title: params.title
  };

  return new ClassicReadingInfo(bundle, initialVoice as VoiceSpecOfAvailableVoice, contentBundlerOptions, thumbnail);
};

export const createClassicReaderFromTextWithoutImport = async (text: string): Promise<ClassicReadingInfo> => {
  const { factory, initialVoice, contentBundlerOptions } = await createBundlerFactory();
  const classicReadingBundler = factory.createClassicReadingBundler();
  const bundle = await promisify(classicReadingBundler.createBundleForPlainText.bind(classicReadingBundler))(text, ImportStartChoice.DoNotStart, null);
  return new ClassicReadingInfo(bundle, initialVoice as VoiceSpecOfAvailableVoice, contentBundlerOptions, {});
};

export const createBookReaderFromFile = async (params: BookReaderFromFileParams, onImportComplete: Callback<SpeechifyURI>): Promise<BookReadingInfo> => {
  const { factory, initialVoice, contentBundlerOptions } = await instrumentAction(createBundlerFactory, 'createBundlerFactory');
  const bookReadingBundler = factory.universalSourcesReadingBundler;
  const bundle = await instrumentAction(
    () => promisify(bookReadingBundler.createBundleForBlob.bind(bookReadingBundler))(params.file, ImportStartChoice.StartImmediately, null),
    'createBundleForBlob'
  );

  const folderId = params.folderId || null;
  const title = params.file.name;
  handleImport({ bundle, folderId, title }, onImportComplete);

  const { numberOfPages } = extractThumbnailInfoFromBook(bundle);

  const thumbnail: ThumbnailInfo = {
    numberOfPages,
    title
  };

  return new BookReadingInfo(bundle, initialVoice as VoiceSpecOfAvailableVoice, contentBundlerOptions, thumbnail, params.file);
};

export const createBookReaderFromUrl = async (params: ReaderFromUrlParams, onImportComplete: Callback<SpeechifyURI>): Promise<BookReadingInfo> => {
  const { factory, initialVoice, contentBundlerOptions } = await instrumentAction(createBundlerFactory, 'createBundlerFactory');
  const bookReadingBundler = factory.universalSourcesReadingBundler;
  const bundle = await instrumentAction(
    () => promisify(bookReadingBundler.createBundleForURL.bind(bookReadingBundler))(params.url, ImportStartChoice.StartImmediately, null),
    'createBundleForUrl'
  );

  const folderId = params.folderId || null;
  const title = params.title || null;
  handleImport({ bundle, folderId, title }, onImportComplete);

  const { numberOfPages } = extractThumbnailInfoFromBook(bundle);

  const thumbnail: ThumbnailInfo = {
    numberOfPages,
    title
  };

  return new BookReadingInfo(bundle, initialVoice as VoiceSpecOfAvailableVoice, contentBundlerOptions, thumbnail, undefined, params.url);
};

export const createBookReaderFromLibraryItem = async (item: IRecord): Promise<BookReadingInfo> => {
  const { factory, initialVoice, contentBundlerOptions } = await createBundlerFactory(item.id);
  const bookReadingBundler = factory.universalSourcesReadingBundler;
  const bundle = await promisify(bookReadingBundler.createBundleForResource.bind(bookReadingBundler))(
    SpeechifyURI.Companion.fromExistingId(SpeechifyEntityType.LIBRARY_ITEM, item.id),
    null
  );

  const thumbnail: ThumbnailInfo = {
    url: item.coverImagePath,
    numberOfPages: item.numPages || null,
    title: item.title
  };

  return new BookReadingInfo(bundle, initialVoice as VoiceSpecOfAvailableVoice, contentBundlerOptions, thumbnail, undefined, undefined);
};

export const createClassicReaderFromLibraryItem = async (item: IRecord): Promise<ClassicReadingInfo> => {
  const { factory, initialVoice, contentBundlerOptions } = await createBundlerFactory(item.id);
  const classicReadingBundler = factory.universalSourcesReadingBundler;

  let bundle: ReadingBundle;

  // TODO: This is a temp hack to account for android scans not loading correctly
  try {
    bundle = await promisify(classicReadingBundler.createBundleForResource.bind(classicReadingBundler))(
      SpeechifyURI.Companion.fromExistingId(SpeechifyEntityType.LIBRARY_ITEM, item.id),
      null
    );
  } catch (e) {
    bundle = await promisify(classicReadingBundler.createBundleForResource.bind(classicReadingBundler))(
      SpeechifyURI.Companion.fromExistingId(SpeechifyEntityType.SCANNED_BOOK, item.id),
      null
    );
    e instanceof Error &&
      logError(e, ErrorSource.READING_EXPERIENCE, {
        context: {
          itemId: item.id
        }
      });
  }

  const thumbnail: ThumbnailInfo = {
    url: item.coverImagePath,
    numberOfPages: item.numPages || null,
    title: item.title
  };

  return new ClassicReadingInfo(bundle, initialVoice as VoiceSpecOfAvailableVoice, contentBundlerOptions, thumbnail);
};

export const createClassicReaderFromUrl = async (params: ReaderFromUrlParams, onImportComplete: Callback<SpeechifyURI>): Promise<ClassicReadingInfo> => {
  const { factory, initialVoice, contentBundlerOptions } = await createBundlerFactory();
  const classicReadingBundler = factory.universalSourcesReadingBundler;
  const bundle = await promisify(classicReadingBundler.createBundleForURL.bind(classicReadingBundler))(params.url, ImportStartChoice.StartImmediately, null);

  const title = await promisify(bundle.contentTitleExtractor.getTitle.bind(bundle.contentTitleExtractor))();

  const folderId = params.folderId || null;

  handleImport({ bundle, folderId, title }, onImportComplete);

  const thumbnail: ThumbnailInfo = {
    title
  };

  return new ClassicReadingInfo(bundle, initialVoice as VoiceSpecOfAvailableVoice, contentBundlerOptions, thumbnail);
};

export const createEmbeddedReader = async (el: Element) => {
  const { factory, initialVoice, contentBundlerOptions } = await createBundlerFactory();
  const embeddedBundler = factory.embeddedReadingBundler;
  const bundle = await promisify(embeddedBundler.createBundleForHtmlElement.bind(embeddedBundler))(el, undefined);

  return new EmbeddedReadingInfo(bundle, initialVoice as VoiceSpecOfAvailableVoice, contentBundlerOptions, {});
};

// ESLint: 'lang' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getFlagFromLanguageCode = (lang: string) => '';

export const getAllVoices = memoizeAsync(
  async (): Promise<VoiceSpec[]> => {
    // FIXME: this is a hack to wait Firebase to finish hydrating the user
    // See (https://linear.app/speechify-inc/issue/WEB-3243/issue-with-opening-items-in-web-app
    await getCurrentUser(auth.firebaseAuth);
    const personalVoices = await speechify.getPersonalVoices();
    const voices = await getDefaultVoiceList().then(getAllVoicesFromList);

    return [
      ...(await getClientVoices()).map(mapVoiceToDefinition).map(generateVoiceSpec),
      ...voices.map(generateVoiceSpec),
      ...personalVoices.map(mapVoiceSpecOfAvailableVoiceToVoiceDefinition).map(generateVoiceSpec)
    ];
  },
  { timeout: 5 * 60 * 1000 }
);
