import Switch from 'components/newListeningExperience/shared/Switch';
import { cn } from 'utils/cn';

export type SettingItemProps = {
  name: string;
  checked: boolean;
  onChange: (newState: boolean) => void;
  icon: JSX.Element;
  fillIconBackground?: boolean;
  state?: 'default' | 'locked';
};

const SettingItem = ({ name, state = 'default', checked, onChange, icon, fillIconBackground }: SettingItemProps) => {
  const isLocked = state === 'locked';
  return (
    <div className="flex items-center justify-between px-spl-7 py-spl-4">
      <div className="flex items-center gap-spl-4">
        <div className={cn('p-spl-2 rounded-100', fillIconBackground && 'bg-bg-secondary')}>{icon}</div>
        <div className="text-subheading-6 text-text-primary">{name}</div>
      </div>
      <div>
        <Switch locked={isLocked} checked={checked} onChange={onChange} />
      </div>
    </div>
  );
};

export default SettingItem;
