import { RecordType } from 'interfaces';
import { logError, logEvent as logEventInObservability } from 'lib/observability';
import * as speechify from 'lib/speechify';

import { AnalyticsBrowser } from '@segment/analytics-next';

import pkg from '../../package.json';
import { browserNameAndVersion } from './browser';

let segment: AnalyticsBrowser;
let userId: $TSFixMe;

export const initAnalytics = () => {
  if (typeof window !== 'undefined') {
    segment = AnalyticsBrowser.load(
      {
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITEKEY,
        cdnURL: process.env.NEXT_PUBLIC_SEGMENT_PROXY_CDN_HOST
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: process.env.NEXT_PUBLIC_SEGMENT_PROXY_API_HOST
          }
        }
      }
    );
  }
};

export const getSource = (pathname: string) => {
  let source = '';

  switch (pathname) {
    case '/':
      source = 'library';
      break;

    case '/item/[id]':
      source = 'listening screen';
      break;

    default:
    // ignore
  }

  return source;
};

function logEvent(eventName: string, data: $TSFixMe = {}) {
  try {
    segment?.track(eventName, data);
  } catch (e) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(e);
  }
}

export const logSegmentEvent = (eventName: string, data: $TSFixMe = {}) => {
  // append information to make analytics consistent with extension
  const [browserName, browserVersion] = browserNameAndVersion('/').split('/');
  const eventData = {
    ...data,
    hostname: window.location.hostname,
    sdkVersion: speechify.sdkVersion,
    usingSDK: true,
    webpage: window.location.href,
    browserName,
    browserVersion,
    webAppVersion: pkg.version
  };

  logEventInObservability(eventName, data);

  if (process.env.NODE_ENV !== 'development') {
    logEvent(eventName, eventData);
  } else {
    !process.env.NEXT_PUBLIC_DEV_DISABLE_NOISY_CONSOLE_LOG && console.debug('segment', eventName, JSON.stringify(eventData));
  }
};

export const segmentIdentifyByUserId = (uid: string) => {
  // TODO(overhaul): add properties for new listening overhaul
  if (process.env.NODE_ENV !== 'development' && uid) {
    userId = uid;
    segment.identify(uid);
  }
};

export const segmentIdentifyWithProps = (properties: $TSFixMe) => {
  if (process.env.NODE_ENV !== 'development') {
    segment.identify(userId, properties);
  }
};

export const setTimeToPlay = (mode: string, recordType: RecordType | string) => {
  localStorage.setItem('timeToPlay', JSON.stringify({ mode, recordType, startTime: new Date().getTime() }));
};
