import { AutoSkipContentUnlockedReason } from 'components/newListeningExperience/settings/Settings';
import { UseListenableContentItemIdState } from 'modules/listening/hooks/useListenableContentItemId';

import { SubscriptionStoreState, useSubscriptionStore } from '../subscriptionStore';

type SkipContentUnlockedResult = {
  skipContentUnlocked: boolean;
  skipContentUnlockedReason?: AutoSkipContentUnlockedReason;
};

export const getIsSkipContentUnlocked = (
  subscriptionStoreState: SubscriptionStoreState,
  itemIdState: UseListenableContentItemIdState
): SkipContentUnlockedResult => {
  subscriptionStoreState ??= useSubscriptionStore.getState();
  if (subscriptionStoreState.isPremium) {
    return {
      skipContentUnlocked: true,
      skipContentUnlockedReason: AutoSkipContentUnlockedReason.Premium
    };
  }

  if (!itemIdState.isLoading && subscriptionStoreState.firstPdfItemId === itemIdState.itemId) {
    return {
      skipContentUnlocked: true,
      skipContentUnlockedReason: AutoSkipContentUnlockedReason.FreeFirstDocument
    };
  }

  return {
    skipContentUnlocked: false
  };
};
