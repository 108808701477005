import { ErrorWithContext } from 'utils/error';

import type { LibraryItem as SDKLibraryItem } from '@speechifyinc/multiplatform-sdk';

import { MultiplatformSDKInstance } from '../../sdk';
import { SDKFacade } from '../_base';
import {
  DOCXItemListenableContent,
  EPUBItemListenableContent,
  HTMLItemListenableContent,
  PDFItemListenableContent,
  ScanItemListenableContent,
  TXTItemListenableContent
} from './item';
import { ListenableContent } from './types';

export class SDKContentFacade extends SDKFacade {
  private static _singleton: SDKContentFacade;
  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKContentFacade._singleton = this;
  }

  static override get singleton(): SDKContentFacade {
    return SDKContentFacade._singleton;
  }

  public createListenableContent = async (item: SDKLibraryItem): Promise<ListenableContent> => {
    const LibraryItem = this.sdk.sdkModule.LibraryItem;
    const isListenableContent = item instanceof LibraryItem.ListenableContent;

    if (!isListenableContent) {
      throw new ErrorWithContext('Tried to convert a non listenable content id into a listenable content', {
        itemId: item.ownerId
      });
    }

    const contentType = item.contentType;
    const SDKContentType = this.sdk.sdkModule.ContentType;

    if (item instanceof LibraryItem.Content) {
      switch (contentType) {
        case SDKContentType.DOCX:
          return new DOCXItemListenableContent(item);
        case SDKContentType.EPUB:
          return new EPUBItemListenableContent(item);
        case SDKContentType.HTML:
          return new HTMLItemListenableContent(item);
        case SDKContentType.PDF:
          return new PDFItemListenableContent(item);
        case SDKContentType.SCAN:
          return new ScanItemListenableContent(item);
        case SDKContentType.TXT:
          return new TXTItemListenableContent(item);
        default:
          throw new ErrorWithContext('Unsupported content type', { contentType: contentType ? contentType.name : 'null' });
      }
    }

    throw new ErrorWithContext('LibraryItem.DeviceLocalContent is not supported yet', {
      ownerId: item.ownerId,
      contentType: `${item.contentType?.type}`
    });
  };
}
