import { ArchiveFilesAdapter, ZipArchiveView, ZipFileEntry } from '@speechifyinc/multiplatform-sdk/api/adapters/archiveFiles';
import JSZip from 'jszip';
import { Callback } from './lib/typeAliases';
import { BinaryContentReadableRandomly } from '@speechifyinc/multiplatform-sdk/api/util/io';
import { callbackFromAsync } from './lib/callbackFromAsync';

export class WebArchiveFilesAdapter extends ArchiveFilesAdapter {
  override createViewOfZip(data: BinaryContentReadableRandomly, callback: Callback<ZipArchiveView>) {
    callbackFromAsync(async () => {
      const zip = new JSZip();
      const blob = data.blob;
      await zip.loadAsync(blob);

      const entries: ZipFileEntry[] = [];

      Object.keys(zip.files).forEach(filename => {
        class WebZipFileEntry extends ZipFileEntry {
          get path() {
            return filename;
          }

          override createBinaryContentReadableRandomly(innerCallback: Callback<BinaryContentReadableRandomly>) {
            callbackFromAsync(async () => {
              const file = zip.file(this.path);
              if (!file) {
                throw new Error(`File ${this.path} not found in the archive.`);
              }

              const contentBlob = await file.async('blob');
              return new BinaryContentReadableRandomly(contentBlob);
            }, innerCallback);
          }
        }

        const zipFileEntry = new WebZipFileEntry();
        entries.push(zipFileEntry);
      });

      class WebZipArchiveView extends ZipArchiveView {
        get entries() {
          return entries;
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        // do nothing here since Blobs get released with dereference
        override destroy(_callback: Callback<void>) {
          callbackFromAsync(async () => {
            return;
          }, _callback);
        }
      }

      return new WebZipArchiveView();
    }, callback);
  }
}
