import { RootState } from 'store';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PreferencesStoreState = {
  // permanent, persisted autoScroll preferences that carries between library sessions
  autoScroll: boolean;

  // temporary autoScroll value only for one library session, doesn't get persisted between sessions
  autoScrollTemporary: boolean;
};

export type SetAutoScrollPayload = {
  value: boolean;
  temporary?: boolean;
};

// previous tech debt from many usePreferenceVariable hooks causing sync issues between components
export const AUTOSCROLL_LOCAL_STORAGE_KEY = 'autoscroll';

const setAutoScroll = (state: PreferencesStoreState, newAutoScroll: boolean) => {
  state.autoScroll = newAutoScroll;
  localStorage.setItem(AUTOSCROLL_LOCAL_STORAGE_KEY, newAutoScroll.toString());
  if (newAutoScroll && !state.autoScrollTemporary) {
    state.autoScrollTemporary = true;
  }
};

const { actions: generatedActions, reducer } = createSlice({
  name: 'preferences',
  initialState: () => {
    if (typeof window === 'undefined') return { autoScroll: true, autoScrollTemporary: true };
    const initialAutoScroll = window.localStorage.getItem('autoscroll') ?? 'true';
    const autoScroll = initialAutoScroll === 'true';
    return {
      autoScroll,
      autoScrollTemporary: autoScroll
    };
  },
  reducers: {
    setAutoscroll: (state, action: PayloadAction<boolean>) => {
      setAutoScroll(state, action.payload);
    },
    toggleAutoscroll: state => {
      setAutoScroll(state, !state.autoScroll);
    },
    setAutoScrollTemporary: (state, action: PayloadAction<boolean>) => {
      state.autoScrollTemporary = action.payload;
    }
  }
});

export const preferencesActions = generatedActions;

export const autoScrollSelector = createSelector(
  (state: RootState) => state.preferences,
  (preferences: PreferencesStoreState) => preferences.autoScroll
);

export const autoScrollTemporarySelector = createSelector(
  (state: RootState) => state.preferences,
  (preferences: PreferencesStoreState) => preferences.autoScrollTemporary
);

export const autoScrollFullStateSelector = createSelector(autoScrollSelector, autoScrollTemporarySelector, (autoScroll, autoScrollTemporary) => ({
  autoScroll,
  autoScrollTemporary
}));

export default reducer;
