import { createNonPersistentStore } from 'lib/zustand';

type AskAIState = {
  playing: boolean;
};

export const useAskAIPlaying = createNonPersistentStore<AskAIState>(() => {
  return {
    playing: false
  };
});

const setAskAIPlaying = (playing: boolean) => {
  useAskAIPlaying.setState({ playing });
};

export const askAIPlayingActions = {
  setAskAIPlaying
};
