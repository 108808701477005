import { UpgradeSource } from 'modules/subscription/utils/UpgradeSource';

import { AnalyticsEventKey } from '../analyticsTypes';
import { logAnalyticsEvent } from '../logAnalyticsEvent';

export const UPGRADE_SOURCE_TO_ANALYTICS_EVENT_KEY: Record<UpgradeSource, AnalyticsEventKey> = {
  [UpgradeSource.ocrUpsell]: AnalyticsEventKey.upgradePressedOcrUpsell,
  [UpgradeSource.skipContentUpsell]: AnalyticsEventKey.upgradePressedDashboard,
  [UpgradeSource.visualCta]: AnalyticsEventKey.upgradePressedAudioVisualCta,
  [UpgradeSource.voiceCloneCta]: AnalyticsEventKey.upgradePressedDashboard,
  [UpgradeSource.speedPickerUpsell]: AnalyticsEventKey.speedPickerUpsell,
  [UpgradeSource.helpCenterMessageBtn]: AnalyticsEventKey.helpCenterMessageUpgrade
};

export const UPGRADE_SOURCE_TO_ANALYTICS_PROPERTIES_FN: Partial<Record<UpgradeSource, () => Record<string, unknown>>> = {
  [UpgradeSource.skipContentUpsell]: () => ({ source: 'skip_header_footer_upsell' }),
  [UpgradeSource.voiceCloneCta]: () => ({ source: window.location.pathname, vcw: true })
};

export const logUpgradeButtonAnalyticsEvent = (source: UpgradeSource) => {
  const analyticsEvent = UPGRADE_SOURCE_TO_ANALYTICS_EVENT_KEY[source];
  if (analyticsEvent) {
    const analyticsProperties = UPGRADE_SOURCE_TO_ANALYTICS_PROPERTIES_FN[source]?.() || {};
    logAnalyticsEvent(analyticsEvent, analyticsProperties);
  }
};
