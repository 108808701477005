import ct from 'countries-and-timezones';
import { ErrorWithContext } from 'utils/error';

import { PushErrorOptions } from '@grafana/faro-core';
import { PushEventOptions } from '@grafana/faro-core/dist/types/api/events/types';
import { type MeasurementsAPI, PushMeasurementOptions } from '@grafana/faro-core/dist/types/api/measurements/types';
import { Faro, getWebInstrumentations, initializeFaro, LogsAPI, MetaUser } from '@grafana/faro-web-sdk';

import { ConsoleInstrumentation } from './ConsoleInstrumentation';

let faro: Faro | undefined;

if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_FARO_COLLECTOR_URL) {
  faro = initializeFaro({
    url: process.env.NEXT_PUBLIC_FARO_COLLECTOR_URL,
    instrumentations: [
      ...getWebInstrumentations({
        // we use custom console instrumentation to log errors as exceptions
        captureConsole: false,
        enablePerformanceInstrumentation: false
      }),
      new ConsoleInstrumentation()
    ],
    app: {
      name: 'Speechify Web App',
      version: process.env.version
    }
  });

  // Wrapping it in a try/catch in case Intl is not available (old browsers)
  try {
    const session = faro.api.getSession();

    faro.api.setSession({
      ...session,
      attributes: {
        ...(session?.attributes || {}),
        country: ct.getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)?.id || '--'
      }
    });
  } catch (e) {
    console.error(e);
  }

  faro.api.pushLog(['Faro initialized']);
}

const log = (...args: Parameters<LogsAPI['pushLog']>) => faro && faro.api.pushLog(...args);

const logError = (error: Error, source: string | undefined = undefined, options: PushErrorOptions = { context: {} }) => {
  if (faro) {
    let errorToLog = error;

    if (errorToLog && errorToLog.message === 'Chrome runtime not available') {
      return;
    }

    if (!(error instanceof Error)) {
      errorToLog = new Error(error);
    }

    let context = source ? { ...options.context, source } : options.context;
    if (error instanceof ErrorWithContext) {
      context = { ...context, ...error.context };
    }

    faro.api.pushError(errorToLog, {
      ...(options || {}),
      context
    });
    const consoleInstrumentation = faro.instrumentations.instrumentations.find(i => i instanceof ConsoleInstrumentation);

    if (consoleInstrumentation) {
      if (consoleInstrumentation.unpatchedConsole) {
        try {
          if (source) {
            consoleInstrumentation.unpatchedConsole.error(errorToLog, source);
          } else {
            consoleInstrumentation.unpatchedConsole.error(errorToLog);
          }
        } catch (e) {
          console.error('Error logging failed through instrumentation, falling back to default console.error', errorToLog, source);
        }
      }
    }
  } else if (source) {
    console.error(error, source);
  } else {
    console.error(error);
  }
};

const logEvent = (name: string, attributes?: Record<string, $TSFixMe>, domain?: string, options?: PushEventOptions) => {
  faro && faro.api.pushEvent(name, prepareData(attributes), domain, options);
};
const logMeasurement = (...args: Parameters<MeasurementsAPI['pushMeasurement']>) => {
  (process.env.NODE_ENV === 'development' || process.env.NEXT_PUBLIC_VERBOSE_MEASUREMENT) &&
    (() => {
      const output = [args[0]['type'], args[0]['values'], args[1] && args[1].context].filter(Boolean);
      console.info('[measure] ', ...output);
    })();
  faro && faro.api.pushMeasurement(...args);
};

const measure = (name: string, time: number, options: PushMeasurementOptions = {}) => {
  logMeasurement(
    {
      type: name,
      values: {
        [name]: time
      }
    },
    options
  );
};

const createMeasurement = (name: string) => {
  const startTime = performance.now();

  return () => {
    const endTime = performance.now() - startTime;
    logMeasurement({
      type: name,
      values: {
        [name]: endTime
      }
    });
  };
};

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
const instrumentAction = async <F extends (...args: any[]) => any>(cb: F, name: string): Promise<ReturnType<F>> => {
  try {
    const end = createMeasurement(name);
    const result = await cb();
    end();
    return result;
  } catch (e) {
    faro && e instanceof Error && faro.api.pushError(e, { type: name });
    throw e;
  }
};

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateSessionAttributes = (attributes: Record<string, any>) => {
  if (faro) {
    const session = faro.api.getSession() || {};

    faro.api.setSession({
      ...session,
      attributes: {
        ...(session?.attributes || {}),
        ...prepareData(attributes)
      }
    });

    faro.api.pushLog(['Faro session updated']);
  }
};

const setUser = (user: MetaUser) => faro && faro.api.setUser(user);

// faro accepts only string values in event attributes
// @ts-expect-error TS(7023): 'prepareData' implicitly has return type 'any' bec... Remove this comment to see the full error message
const prepareData = (data: $TSFixMe) => {
  if (Array.isArray(data)) {
    return data.map(prepareData);
  }

  if (data !== null && typeof data === 'object') {
    return Object.keys(data).reduce((acc, key) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[key] = prepareData(data[key]);
      return acc;
    }, {});
  }

  if (typeof data === 'boolean' || typeof data === 'number') {
    return String(data);
  }

  return data;
};

export { createMeasurement, faro, instrumentAction, log, logError, logEvent, logMeasurement, measure, setUser, updateSessionAttributes };
