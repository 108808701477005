import { ErrorSource } from 'constants/errors';
import { logError } from 'lib/observability';
import * as speechify from 'lib/speechify';
import { isAnonymous as isUserAnonymous } from 'lib/speechify';
import { User } from 'lib/speechify/auth';
import nookies from 'nookies';
import { logSegmentEvent, segmentIdentifyByUserId } from 'utils/analytics';
import { FIRST_PDF_DOCUMENT, getCustomAccountSetting, refreshAccountSettings } from 'utils/baseAccountSettings';
import * as extension from 'utils/extension';
import { bootIntercom } from 'utils/intercom';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { SPAM_EMAIL_DOMAINS } from './constants';

type loginType = { email: string; password: string };
type signUpType = loginType & { campaignsEnabled: boolean };

const getSessionCookie = async (token: string) =>
  await fetch('/api/auth/sign-in', { method: 'GET', cache: 'no-store', headers: { Authorization: `Bearer ${token}` } });
export const resetSessionCookie = async () => await fetch('/api/auth/sign-out', { method: 'GET', cache: 'no-store' });

const onboardingSignOut = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const onboardingLogoutUrl = `${process.env.NEXT_PUBLIC_FOYER_URL}/api/auth/sign-out`;

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = onboardingLogoutUrl;

    iframe.onload = () => {
      document.body.removeChild(iframe);
      resolve(); // resolve once the iframe is loaded
    };

    iframe.onerror = e => {
      document.body.removeChild(iframe);
      reject(e); // reject if error
    };

    document.body.appendChild(iframe);
  });
};

const signOutSameDomainOnboarding = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const onboardingLogoutUrl = `${process.env.NEXT_PUBLIC_SAME_DOMAIN_ONBOARDING_URL}/api/onboarding/auth/sign-out`;

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = onboardingLogoutUrl;

    iframe.onload = () => {
      document.body.removeChild(iframe);
      resolve(); // resolve once the iframe is loaded
    };

    iframe.onerror = e => {
      document.body.removeChild(iframe);
      reject(e); // reject if error
    };

    document.body.appendChild(iframe);
  });
};

export const login = createAsyncThunk('auth/login', async ({ email, password }: loginType, { rejectWithValue }) => {
  let uid: string;

  try {
    const result = await speechify.auth.signInWithEmailAndPassword(email, password);
    uid = result.user?.uid;
    await getSessionCookie(await result.user.getIdToken());
    return;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  } finally {
    // @ts-expect-error TS(2454): Variable 'uid' is used before being assigned.
    if (uid) {
      segmentIdentifyByUserId(uid);
      logSegmentEvent('web_app_user_authentication', { source: 'email', mode: 'login' });
    }
  }
});

export const createAnonymousUser = async () => {
  const { user } = await speechify.auth.signInAnonymously();
  await getSessionCookie(await user.getIdToken());
  return user;
};

export const loginAnonymously = createAsyncThunk('auth/loginAnonymously', async (_: void, { dispatch, rejectWithValue }) => {
  let uid: string;

  try {
    const user = await createAnonymousUser();
    uid = user?.uid;
    dispatch(setUser(user));

    return;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  } finally {
    // @ts-expect-error TS(2454): Variable 'uid' is used before being assigned.
    if (uid) {
      segmentIdentifyByUserId(uid);
      logSegmentEvent('web_app_user_authentication', { source: 'Anonymous', mode: 'login' });
    }
  }
});

export const loginWithCustomToken = createAsyncThunk('auth/loginWithCustomToken', async (accessToken: string, { dispatch, rejectWithValue }) => {
  let uid: string;

  try {
    const result = await speechify.auth.signInWithCustomToken(accessToken);
    uid = result.user?.uid;
    await getSessionCookie(await result.user.getIdToken());
    dispatch(setUser(result.user));

    return;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  } finally {
    // @ts-expect-error TS(2454): Variable 'uid' is used before being assigned.
    if (uid) {
      segmentIdentifyByUserId(uid);
      logSegmentEvent('web_app_user_authentication', { source: 'customToken', mode: 'login' });
    }
  }
});

export const loginWithApple = createAsyncThunk('auth/loginWithApple', async (_: void, { rejectWithValue }) => {
  const appleProvider = new speechify.auth.OAuthProvider('apple.com');

  appleProvider.addScope('name');
  appleProvider.addScope('email');

  let uid: string;

  try {
    const result = await speechify.auth.signInWithPopup(appleProvider);

    uid = result.user?.uid;
    await getSessionCookie(await result.user.getIdToken());

    return;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  } finally {
    // @ts-expect-error TS(2454): Variable 'uid' is used before being assigned.
    if (uid) {
      segmentIdentifyByUserId(uid);
      logSegmentEvent('web_app_user_authentication', { source: 'apple', mode: 'login' });
    }
  }
});

export const loginWithFacebook = createAsyncThunk('auth/loginWithFacebook', async (_: void, { rejectWithValue }) => {
  const fbProvider = new speechify.auth.FacebookAuthProvider();

  let uid: string;

  try {
    const result = await speechify.auth.signInWithPopup(fbProvider);

    uid = result.user?.uid;
    await getSessionCookie(await result.user.getIdToken());
    return;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  } finally {
    // @ts-expect-error TS(2454): Variable 'uid' is used before being assigned.
    if (uid) {
      segmentIdentifyByUserId(uid);
      logSegmentEvent('web_app_user_authentication', { source: 'facebook', mode: 'login' });
    }
  }
});

export const loginWithGoogle = createAsyncThunk('auth/loginWithGoogle', async (_: void, { rejectWithValue }) => {
  const googleProvider = new speechify.auth.GoogleAuthProvider();

  let uid: string;

  try {
    const result = await speechify.auth.signInWithPopup(googleProvider);

    uid = result.user?.uid;
    await getSessionCookie(await result.user.getIdToken());
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  } finally {
    // @ts-expect-error TS(2454): Variable 'uid' is used before being assigned.
    if (uid) {
      segmentIdentifyByUserId(uid);
      logSegmentEvent('web_app_user_authentication', { source: 'google', mode: 'login' });
    }
  }
});

export const logout = createAsyncThunk('auth/logout', async (_: void, { rejectWithValue }) => {
  try {
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    window['uppy'] = null;

    nookies.destroy({}, 'lpobtoken', { domain: '.speechify.com' });
    nookies.set({}, 'authsync', 'out', { path: '/', domain: '.speechify.com' });

    await resetSessionCookie();
    await speechify.auth.signOut();
    await resetSessionCookie();
    await extension.logOut();
    await onboardingSignOut();
    await signOutSameDomainOnboarding();

    window.location.href = '/login';
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const sendPasswordResetEmail = createAsyncThunk('auth/sendPasswordResetEmail', async (email: string, { rejectWithValue }) => {
  try {
    const response = await speechify.auth.sendPasswordResetEmail(email);
    return response;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const setUser = createAsyncThunk('auth/setUser', async (currentUser: User, { getState, rejectWithValue }) => {
  try {
    if (currentUser) {
      const state: $TSFixMe = getState();

      const {
        displayName,
        email,
        uid,
        metadata: { creationTime, lastSignInTime },
        emailVerified
      } = currentUser;

      const isAnonymous = isUserAnonymous(currentUser);
      const user = { displayName, email, isAnonymous, uid, metadata: { creationTime, lastSignInTime }, emailVerified };

      let extensionSettings = (await extension.getSettings()) || state.auth?.user?.extensionSettings;

      if (!extensionSettings?.voice?.name) {
        const landingPageVoiceName = nookies.get().lpvoice;

        if (landingPageVoiceName) {
          const voice = { name: landingPageVoiceName };
          extension.setVoice(voice);

          extensionSettings = {
            ...extensionSettings,
            voice
          };
        }
      }

      if (!extensionSettings?.playbackSpeed) {
        const landingPageSpeed = Number(nookies.get().lppbspeed);

        if (landingPageSpeed) {
          extension.setPlaybackSpeed(landingPageSpeed);

          extensionSettings = {
            ...extensionSettings,
            playbackSpeed: landingPageSpeed
          };
        }
      }

      const allSubscriptions = await speechify.getAllSubscriptionsAndEntitlements();
      // @ts-expect-error TS(2345): Argument of type 'Nullable<SubscriptionAndEntitlem... Remove this comment to see the full error message
      const { ttsSubscription, ttsEntitlements } = speechify.getTtsSubscriptionAndEntitlements(allSubscriptions);
      const subscription = speechify.parseUnserializables(speechify.toPOJO(ttsSubscription));
      const entitlements = speechify.parseUnserializables(speechify.toPOJO(ttsEntitlements));

      // @ts-expect-error TS(2345): Argument of type 'Nullable<SubscriptionAndEntitlem... Remove this comment to see the full error message
      const hasStudioSubscription = speechify.hasStudioSubscription(allSubscriptions);

      bootIntercom({ ...currentUser, entitlements, subscription }, {});

      // refresh account settings for the new user
      await refreshAccountSettings();

      const res = await Promise.all([
        getCustomAccountSetting('fileUploaded'),
        getCustomAccountSetting('redirectGoogleDoc'),
        getCustomAccountSetting('extensionPinned'),
        getCustomAccountSetting('extensionInstalled'),
        getCustomAccountSetting('clickedStartListening'),
        getCustomAccountSetting('mobileAppInstalled'),
        getCustomAccountSetting('hasSetDailyListeningGoal'),
        getCustomAccountSetting(FIRST_PDF_DOCUMENT)
      ]);

      return {
        ...user,
        redirectGoogleDoc: Boolean(res[1]),
        extensionPinned: Boolean(res[2]),
        fileUploaded: Boolean(res[0]),
        extensionInstalled: Boolean(res[3]),
        clickedStartListening: Boolean(res[4]),
        mobileAppInstalled: Boolean(res[5]),
        hasSetDailyListeningGoal: Boolean(res[6]),
        hasStudioSubscription,
        firstPdfDocument: res[7],
        entitlements,
        extensionSettings,
        subscription
      };
    }
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  }
});

export const setPlaybackSpeed = createAsyncThunk('auth/setPlaybackSpeed', async (playbackSpeed: number) => {
  const newPlaybackSpeed = Math.max(Math.min(playbackSpeed, 4.5), 0.5);
  extension.setPlaybackSpeed(newPlaybackSpeed);
  return newPlaybackSpeed;
});

export const setVoice = createAsyncThunk(
  'auth/setVoice',
  async (voice: { displayName: string; engine: string; gender: string; languageCode: string; name: string }) => {
    extension.setVoice(voice);
    return voice;
  }
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async ({ email, password, campaignsEnabled, source = 'email' }: signUpType & { source: string }, { dispatch, rejectWithValue }) => {
    let uid: string;
    try {
      const emailDomain = email?.split('@')[1] || '';

      if (SPAM_EMAIL_DOMAINS.has(emailDomain.toLowerCase())) {
        return rejectWithValue({ message: 'Invalid email address' });
      }
      const result = await speechify.auth.createUserWithEmailAndPassword(email, password);
      uid = result.user.uid;
      dispatch(setUser(result.user));
      await getSessionCookie(await result.user.getIdToken());
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(error, ErrorSource.AUTH);
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (!error.response) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        throw error.message;
      }

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      rejectWithValue(error.response.data);
    } finally {
      // @ts-expect-error TS(2454): Variable 'uid' is used before being assigned.
      segmentIdentifyByUserId(uid);

      logSegmentEvent('web_app_user_authentication', { source: source, mode: 'signup' });
      sendWelcomeEmail(email, campaignsEnabled);
    }
  }
);

const sendWelcomeEmail = (email: string, campaignsEnabled: boolean) => {
  try {
    fetch(`https://us-central1-${process.env.NEXT_PUBLIC_FB_PROJECT_ID}.cloudfunctions.net/sendWelcomeEmail`, {
      method: 'post',
      body: JSON.stringify({
        email,
        source: 'email',
        emailCampaingsEnabled: campaignsEnabled
      })
    });
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
  }
};

export const signUpWithApple = createAsyncThunk('auth/signUpWithApple', async (_: void, { dispatch, rejectWithValue }) => {
  const appleProvider = new speechify.auth.OAuthProvider('apple.com');

  appleProvider.addScope('name');
  appleProvider.addScope('email');

  let uid: string;

  try {
    const result = await speechify.auth.signInWithPopup(appleProvider);

    dispatch(setUser(result.user));
    uid = result.user.uid;

    return;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  } finally {
    // @ts-expect-error TS(2454): Variable 'uid' is used before being assigned.
    segmentIdentifyByUserId(uid);
    logSegmentEvent('web_app_user_authentication', { source: 'apple', mode: 'login' });
  }
});

export const signUpWithFacebook = createAsyncThunk('auth/signUpWithFacebook', async (_: void, { dispatch, rejectWithValue }) => {
  const fbProvider = new speechify.auth.FacebookAuthProvider();

  let uid: string;

  try {
    const result = await speechify.auth.signInWithPopup(fbProvider);
    dispatch(setUser(result.user));
    uid = result.user?.uid;

    return;
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  } finally {
    // @ts-expect-error TS(2454): Variable 'uid' is used before being assigned.
    if (uid) {
      segmentIdentifyByUserId(uid);
      logSegmentEvent('web_app_user_authentication', { source: 'facebook', mode: 'login' });
    }
  }
});

export const signUpWithGoogle = createAsyncThunk('auth/signUpWithGoogle', async (_: void, { dispatch, rejectWithValue }) => {
  const googleProvider = new speechify.auth.GoogleAuthProvider();

  let uid: string;

  try {
    const result = await speechify.auth.signInWithPopup(googleProvider);
    dispatch(setUser(result.user));
    uid = result.user.uid;
    await getSessionCookie(await result.user.getIdToken());
  } catch (error) {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logError(error, ErrorSource.AUTH);
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (!error.response) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      throw error.message;
    }

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    rejectWithValue(error.response.data);
  } finally {
    // @ts-expect-error TS(2454): Variable 'uid' is used before being assigned.
    segmentIdentifyByUserId(uid);
    logSegmentEvent('web_app_user_authentication', { source: 'google', mode: 'login' });
  }
});

export const updateProfile = createAsyncThunk('auth/updateProfile', async (profile: { displayName: string }) => {
  speechify.auth.updateProfile(profile);
  return profile;
});
