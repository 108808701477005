import { type Config } from 'tailwindcss';
import defaultTheme from 'tailwindcss/defaultTheme';

export const extendedTheme = {
  variables: ({ theme }: { theme: (variable: string) => string }) => ({
    DEFAULT: {
      colors: {
        bg: {
          'primary-cta': theme('colors.spl.electric.400'),
          'secondary-cta': theme('colors.spl.electric.350'),
          primary: theme('colors.spl.glass.0'),
          'primary-inverted': theme('colors.spl.glass.700'),
          secondary: theme('colors.spl.glass.200'),
          'secondary-inverted': theme('colors.spl.glass.800'),
          tertiary: theme('colors.spl.glass.300'),
          accent: theme('colors.spl.electric.200'),
          alert: theme('colors.spl.yellow.200'),
          success: theme('colors.spl.green.200'),
          critical: theme('colors.spl.red.200')
        },
        border: {
          'primary-cta': theme('colors.spl.electric.400'),
          'primary-cta-hovered': theme('colors.spl.electric.400h'),
          'primary-cta-pressed': theme('colors.spl.electric.400p'),
          'secondary-cta': theme('colors.spl.electric.350'),
          'secondary-cta-hovered': theme('colors.spl.electric.350h'),
          'secondary-cta-pressed': theme('colors.spl.electric.350p'),
          primary: theme('colors.spl.glass.300'),
          'primary-hovered': theme('colors.spl.glass.300h'),
          'primary-pressed': theme('colors.spl.glass.300p'),
          'primary-inverted': theme('colors.spl.glass.600'),
          'primary-inverted-hovered': theme('colors.spl.glass.600h'),
          'primary-inverted-pressed': theme('colors.spl.glass.600p'),
          disabled: theme('colors.spl.dsbl.200'),
          critical: theme('colors.spl.red.400')
        },
        button: {
          'primary-cta': theme('colors.spl.electric.400'),
          'primary-cta-hovered': theme('colors.spl.electric.400h'),
          'primary-cta-pressed': theme('colors.spl.electric.400p'),
          'secondary-cta': theme('colors.spl.electric.350'),
          'secondary-cta-hovered': theme('colors.spl.electric.350h'),
          'secondary-cta-pressed': theme('colors.spl.electric.350p'),
          'primary-cta-inverted': theme('colors.spl.glass.0'),
          'secondary-cta-accent': theme('colors.spl.electric.200'),
          'secondary-cta-accent-hovered': theme('colors.spl.electric.200h'),
          'secondary-cta-accent-pressed': theme('colors.spl.electric.200p'),
          primary: theme('colors.spl.glass.0'),
          'primary-hovered': theme('colors.spl.glass.0h'),
          'primary-pressed': theme('colors.spl.glass.0p'),
          secondary: theme('colors.spl.glass.200'),
          'secondary-hovered': theme('colors.spl.glass.200h'),
          'secondary-pressed': theme('colors.spl.glass.200p'),
          critical: theme('colors.spl.red.400'),
          'critical-hovered': theme('colors.spl.red.400h'),
          'critical-pressed': theme('colors.spl.red.400p'),
          disabled: theme('colors.spl.dsbl.200')
        },
        highlight: {
          'listening-sentence': theme('colors.spl.hglt.200'),
          'listening-word': theme('colors.spl.hglt.300'),
          'hover-sentence': theme('colors.spl.yellow.200'),
          'hover-word': theme('colors.spl.yellow.400')
        },
        icon: {
          'primary-cta': theme('colors.spl.glass.0'),
          'primary-cta-inverted': theme('colors.spl.electric.400'),
          primary: theme('colors.spl.glass.700'),
          'primary-hovered': theme('colors.spl.glass.700h'),
          'primary-pressed': theme('colors.spl.glass.700p'),
          secondary: theme('colors.spl.glass.500'),
          'secondary-inverted': theme('colors.spl.glass.350'),
          tertiary: theme('colors.spl.glass.400'),
          disabled: theme('colors.spl.dsbl.300'),
          success: theme('colors.spl.green.400'),
          'success-hovered': theme('colors.spl.green.400h'),
          'success-pressed': theme('colors.spl.green.400p'),
          critical: theme('colors.spl.red.400'),
          'critical-hovered': theme('colors.spl.red.400h'),
          'critical-pressed': theme('colors.spl.red.400p'),
          alert: theme('colors.spl.yellow.400'),
          accent: theme('colors.spl.electric.350')
        },
        surface: {
          primary: theme('colors.spl.glass.0'),
          'primary-hovered': theme('colors.spl.glass.0h'),
          'primary-pressed': theme('colors.spl.glass.0p'),
          secondary: theme('colors.spl.glass.200'),
          'secondary-hovered': theme('colors.spl.glass.200h'),
          'secondary-pressed': theme('colors.spl.glass.200p'),
          disabled: theme('colors.spl.glass.200'),
          accent: theme('colors.spl.electric.200'),
          success: theme('colors.spl.green.200'),
          alert: theme('colors.spl.yellow.200'),
          critical: theme('colors.spl.red.200')
        },
        text: {
          'button-primary-cta': theme('colors.spl.glass.0'),
          'button-primary-cta-inverted': theme('colors.spl.electric.400'),
          primary: theme('colors.spl.glass.700'),
          'primary-inverted': theme('colors.spl.glass.0'),
          disabled: theme('colors.spl.dsbl.300'),
          secondary: theme('colors.spl.glass.500'),
          'secondary-inverted': theme('colors.spl.glass.350'),
          tertiary: theme('colors.spl.glass.400'),
          placeholder: theme('colors.spl.glass.400'),
          success: theme('colors.spl.green.400'),
          alert: theme('colors.spl.yellow.400'),
          critical: theme('colors.spl.red.400'),
          accent: theme('colors.spl.electric.350')
        },
        'text-field': {
          default: theme('colors.spl.glass.0')
        }
      },
      shadow: {
        soft: '#00000014',
        hard: '#00000029',
        positioning: {
          1: '2px',
          2: '4px',
          3: '8px',
          4: '12px',
          none: '0px'
        },
        blur: {
          1: '4px',
          2: '12px',
          3: '24px',
          4: '48px',
          none: '0px'
        },
        spread: {
          1: '-4px',
          2: '-8px',
          3: '-12px',
          4: '-16px',
          5: '-20px',
          6: '-24px',
          none: '0px'
        }
      }
    }
  }),
  darkVariables: ({ theme }: { theme: (variable: string) => string }) => ({
    DEFAULT: {
      colors: {
        bg: {
          'primary-cta': theme('colors.spl.electric.300'),
          'secondary-cta': theme('colors.spl.electric.250'),
          primary: theme('colors.spl.glass.700'),
          'primary-inverted': theme('colors.spl.glass.0'),
          secondary: theme('colors.spl.glass.800'),
          'secondary-inverted': theme('colors.spl.glass.200'),
          tertiary: theme('colors.spl.glass.600'),
          accent: theme('colors.spl.electric.500'),
          alert: theme('colors.spl.yellow.500'),
          success: theme('colors.spl.green.500'),
          critical: theme('colors.spl.red.500')
        },
        border: {
          'primary-cta': theme('colors.spl.electric.300'),
          'primary-cta-hovered': theme('colors.spl.electric.300h'),
          'primary-cta-pressed': theme('colors.spl.electric.300p'),
          'secondary-cta': theme('colors.spl.electric.250'),
          'secondary-cta-hovered': theme('colors.spl.electric.250h'),
          'secondary-cta-pressed': theme('colors.spl.electric.250p'),
          primary: theme('colors.spl.glass.600'),
          'primary-hovered': theme('colors.spl.glass.600h'),
          'primary-pressed': theme('colors.spl.glass.600p'),
          'primary-inverted': theme('colors.spl.glass.300'),
          'primary-inverted-hovered': theme('colors.spl.glass.300h'),
          'primary-inverted-pressed': theme('colors.spl.glass.300p'),
          disabled: theme('colors.spl.dsbl.500'),
          critical: theme('colors.spl.red.300')
        },
        button: {
          'primary-cta': theme('colors.spl.electric.300'),
          'primary-cta-hovered': theme('colors.spl.electric.300h'),
          'primary-cta-pressed': theme('colors.spl.electric.300p'),
          'secondary-cta': theme('colors.spl.electric.250'),
          'secondary-cta-hovered': theme('colors.spl.electric.250h'),
          'secondary-cta-pressed': theme('colors.spl.electric.250p'),
          'primary-cta-inverted': theme('colors.spl.glass.700'),
          'secondary-cta-accent': theme('colors.spl.electric.500'),
          'secondary-cta-accent-hovered': theme('colors.spl.electric.500h'),
          'secondary-cta-accent-pressed': theme('colors.spl.electric.500p'),
          primary: theme('colors.spl.glass.700'),
          'primary-hovered': theme('colors.spl.glass.700h'),
          'primary-pressed': theme('colors.spl.glass.700p'),
          secondary: theme('colors.spl.glass.800'),
          'secondary-hovered': theme('colors.spl.glass.800h'),
          'secondary-pressed': theme('colors.spl.glass.800p'),
          critical: theme('colors.spl.red.300'),
          'critical-hovered': theme('colors.spl.red.300h'),
          'critical-pressed': theme('colors.spl.red.300p'),
          disabled: theme('colors.spl.dsbl.500')
        },
        highlight: {
          'listening-sentence': theme('colors.spl.hglt.500'),
          'listening-word': theme('colors.spl.hglt.400'),
          'hover-sentence': theme('colors.spl.yellow.500'),
          'hover-word': theme('colors.spl.yellow.300')
        },
        icon: {
          'primary-cta': theme('colors.spl.glass.700'),
          'primary-cta-inverted': theme('colors.spl.electric.300'),
          primary: theme('colors.spl.glass.0'),
          'primary-hovered': theme('colors.spl.glass.0h'),
          'primary-pressed': theme('colors.spl.glass.0p'),
          secondary: theme('colors.spl.glass.350'),
          'secondary-inverted': theme('colors.spl.glass.500'),
          tertiary: theme('colors.spl.glass.400'),
          disabled: theme('colors.spl.dsbl.400'),
          success: theme('colors.spl.green.300'),
          'success-hovered': theme('colors.spl.green.400h'),
          'success-pressed': theme('colors.spl.green.400p'),
          critical: theme('colors.spl.red.300'),
          'critical-hovered': theme('colors.spl.red.300h'),
          'critical-pressed': theme('colors.spl.red.300p'),
          alert: theme('colors.spl.yellow.300'),
          accent: theme('colors.spl.electric.250')
        },
        surface: {
          primary: theme('colors.spl.glass.700'),
          'primary-hovered': theme('colors.spl.glass.700h'),
          'primary-pressed': theme('colors.spl.glass.700p'),
          secondary: theme('colors.spl.glass.800'),
          'secondary-hovered': theme('colors.spl.glass.800h'),
          'secondary-pressed': theme('colors.spl.glass.800p'),
          disabled: theme('colors.spl.glass.800'),
          accent: theme('colors.spl.electric.500'),
          success: theme('colors.spl.green.500'),
          alert: theme('colors.spl.yellow.500'),
          critical: theme('colors.spl.red.500')
        },
        text: {
          'button-primary-cta': theme('colors.spl.glass.700'),
          'button-primary-cta-inverted': theme('colors.spl.electric.300'),
          primary: theme('colors.spl.glass.0'),
          'primary-inverted': theme('colors.spl.glass.700'),
          disabled: theme('colors.spl.dsbl.400'),
          secondary: theme('colors.spl.glass.350'),
          'secondary-inverted': theme('colors.spl.glass.500'),
          tertiary: theme('colors.spl.glass.400'),
          placeholder: theme('colors.spl.glass.400'),
          success: theme('colors.spl.green.300'),
          alert: theme('colors.spl.yellow.300'),
          critical: theme('colors.spl.red.300'),
          accent: theme('colors.spl.electric.250')
        },
        'text-field': {
          default: theme('colors.spl.glass.700')
        }
      },
      shadow: {
        soft: '#00000052',
        hard: '#000000a3',
        positioning: {
          1: '4px',
          2: '6px',
          3: '12px',
          4: '16px',
          none: '0px'
        },
        blur: {
          1: '6px',
          2: '16px',
          3: '30px',
          4: '58px',
          none: '0px'
        },
        spread: {
          1: '-6px',
          2: '-12px',
          3: '-16px',
          4: '-20px',
          5: '-24px',
          6: '-28px',
          none: '0px'
        }
      }
    }
  }),
  container: {
    center: true
  },
  corePlugins: {
    fontSmoothing: false,
    outline: false
  },
  animation: {
    loadingPulse: 'loadingPulse 800ms linear infinite alternate',
    fade: 'fadeIn 1s ease-in-out',
    'spin-slow': 'spin 2.5s linear infinite',
    indeterminiteProgress: 'indeterminiteProgress 1s ease-in-out infinite alternate',
    circularSpin: '2s linear 0s infinite normal none running circularSpin'
  },
  keyframes: () => ({
    fadeIn: {
      '0%': { opacity: '0' },
      '100%': { opacity: '1' }
    },
    circularSpin: {
      '0%': { transform: 'rotate(-90deg)' },
      '100%': { transform: 'rotate(270deg)' }
    },
    loadingPulse: {
      '0%': { transform: 'scale(1)' },
      '100%': { transform: 'scale(1.2)' }
    },
    indeterminiteProgress: {
      '0%': { transform: 'translateX(-100%)' },
      '6%': { transform: 'translateX(-100%)' },
      '40%': { transform: 'translateX(0)' },
      '60%': { transform: 'translateX(0)' },
      '94%': { transform: 'translateX(100%)' },
      '100%': { transform: 'translateX(100%)' }
    }
  }),
  boxShadow: {
    100: '0px 1px 2px 0px #0000001A',
    200: '0px 2px 4px 1px rgba(0, 0, 0, 0.10)',
    300: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    400: '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)',
    500: '0px 0px 1px 0px #0000000A, 0px 4px 8px 0px #0000000A, 0px 16px 24px 0px #0000000A, 0px 24px 32px 0px #0000000A',
    summaryOpt:
      ' 0px 172px 48px 0px rgba(46, 88, 255, 0.00), 0px 110px 44px 0px rgba(46, 88, 255, 0.01), 0px 62px 37px 0px rgba(46, 88, 255, 0.02), 0px 28px 28px 0px rgba(46, 88, 255, 0.03), 0px 7px 15px 0px rgba(46, 88, 255, 0.04)',
    'spl-100': 'var(--shadow-positioning-none) var(--shadow-positioning-1) var(--shadow-blur-1) var(--shadow-spread-none) var(--shadow-soft)',
    'spl-150': 'var(--shadow-positioning-none) var(--shadow-positioning-2) var(--shadow-blur-2) var(--shadow-spread-1) var(--shadow-hard)',
    'spl-200': 'var(--shadow-positioning-none) var(--shadow-positioning-2) var(--shadow-blur-2) 0px var(--shadow-hard)',
    'spl-300': ' var(--shadow-positioning-none) var(--shadow-positioning-1) var(--shadow-blur-4) var(--shadow-spread-none) var(--shadow-hard)'
  },
  colors: {
    bg: {
      'primary-cta': 'var(--colors-bg-primary-cta)',
      'secondary-cta': 'var(--colors-bg-secondary-cta)',
      primary: 'var(--colors-bg-primary)',
      'primary-inverted': 'var(--colors-bg-primary-inverted)',
      secondary: 'var(--colors-bg-secondary)',
      'secondary-inverted': 'var(--colors-bg-secondary-inverted)',
      tertiary: 'var(--colors-bg-tertiary)',
      accent: 'var(--colors-bg-accent)',
      alert: 'var(--colors-bg-alert)',
      success: 'var(--colors-bg-success)',
      critical: 'var(--colors-bg-critical)'
    },
    border: {
      'primary-cta': 'var(--colors-border-primary-cta)',
      'primary-cta-hovered': 'var(--colors-border-primary-cta-hovered)',
      'primary-cta-pressed': 'var(--colors-border-primary-cta-pressed)',
      'secondary-cta': 'var(--colors-border-secondary-cta)',
      'secondary-cta-hovered': 'var(--colors-border-secondary-cta-hovered)',
      'secondary-cta-pressed': 'var(--colors-border-secondary-cta-pressed)',
      primary: 'var(--colors-border-primary)',
      'primary-hovered': 'var(--colors-border-primary-hovered)',
      'primary-pressed': 'var(--colors-border-primary-pressed)',
      'primary-inverted': 'var(--colors-border-primary-inverted)',
      'primary-inverted-hovered': 'var(--colors-border-primary-inverted-hovered)',
      'primary-inverted-pressed': 'var(--colors-border-primary-inverted-pressed)',
      disabled: 'var(--colors-border-disabled)',
      critical: 'var(--colors-border-critical)'
    },
    button: {
      'primary-cta': 'var(--colors-button-primary-cta)',
      'primary-cta-hovered': 'var(--colors-button-primary-cta-hovered)',
      'primary-cta-pressed': 'var(--colors-button-primary-cta-pressed)',
      'secondary-cta': 'var(--colors-button-secondary-cta)',
      'secondary-cta-hovered': 'var(--colors-button-secondary-cta-hovered)',
      'secondary-cta-pressed': 'var(--colors-button-secondary-cta-pressed)',
      'primary-cta-inverted': 'var(--colors-button-primary-cta-inverted)',
      'secondary-cta-accent': 'var(--colors-button-secondary-cta-accent)',
      'secondary-cta-accent-hovered': 'var(--colors-button-secondary-cta-accent-hovered)',
      'secondary-cta-accent-pressed': 'var(--colors-button-secondary-cta-accent-pressed)',
      primary: 'var(--colors-button-primary)',
      'primary-hovered': 'var(--colors-button-primary-hovered)',
      'primary-pressed': 'var(--colors-button-primary-pressed)',
      secondary: 'var(--colors-button-secondary)',
      'secondary-hovered': 'var(--colors-button-secondary-hovered)',
      'secondary-pressed': 'var(--colors-button-secondary-pressed)',
      critical: 'var(--colors-button-critical)',
      'critical-hovered': 'var(--colors-button-critical-hovered)',
      'critical-pressed': 'var(--colors-button-critical-pressed)',
      disabled: 'var(--colors-button-disabled)'
    },
    highlight: {
      'listening-sentence': 'var(--colors-highlight-listening-sentence)',
      'listening-word': 'var(--colors-highlight-listening-word)',
      'hover-sentence': 'var(--colors-highlight-hover-sentence)',
      'hover-word': 'var(--colors-highlight-hover-word)'
    },
    icon: {
      'primary-cta': 'var(--colors-icon-primary-cta)',
      'primary-cta-inverted': 'var(--colors-icon-primary-cta-inverted)',
      primary: 'var(--colors-icon-primary)',
      'primary-hovered': 'var(--colors-icon-primary-hovered)',
      'primary-pressed': 'var(--colors-icon-primary-pressed)',
      secondary: 'var(--colors-icon-secondary)',
      'secondary-inverted': 'var(--colors-icon-secondary-inverted)',
      tertiary: 'var(--colors-icon-tertiary)',
      disabled: 'var(--colors-icon-disabled)',
      success: 'var(--colors-icon-success)',
      'success-hovered': 'var(--colors-icon-success-hovered)',
      'success-pressed': 'var(--colors-icon-success-pressed)',
      critical: 'var(--colors-icon-critical)',
      'critical-hovered': 'var(--colors-icon-critical-hovered)',
      'critical-pressed': 'var(--colors-icon-critical-pressed)',
      alert: 'var(--colors-icon-alert)',
      accent: 'var(--colors-icon-accent)'
    },
    surface: {
      primary: 'var(--colors-surface-primary)',
      'primary-hovered': 'var(--colors-surface-primary-hovered)',
      'primary-pressed': 'var(--colors-surface-primary-pressed)',
      secondary: 'var(--colors-surface-secondary)',
      'secondary-hovered': 'var(--colors-surface-secondary-hovered)',
      'secondary-pressed': 'var(--colors-surface-secondary-pressed)',
      disabled: 'var(--colors-surface-disabled)',
      accent: 'var(--colors-surface-accent)',
      success: 'var(--colors-surface-success)',
      alert: 'var(--colors-surface-alert)',
      critical: 'var(--colors-surface-critical)'
    },
    text: {
      'button-primary-cta': 'var(--colors-text-button-primary-cta)',
      'button-primary-cta-inverted': 'var(--colors-text-button-primary-cta-inverted)',
      primary: 'var(--colors-text-primary)',
      'primary-inverted': 'var(--colors-text-primary-inverted)',
      disabled: 'var(--colors-text-disabled)',
      secondary: 'var(--colors-text-secondary)',
      'secondary-inverted': 'var(--colors-text-secondary-inverted)',
      tertiary: 'var(--colors-text-tertiary)',
      placeholder: 'var(--colors-text-placeholder)',
      success: 'var(--colors-text-success)',
      alert: 'var(--colors-text-alert)',
      critical: 'var(--colors-text-critical)',
      accent: 'var(--colors-text-accent)'
    },
    'text-field': {
      default: 'var(--colors-text-field-default)'
    },
    spl: {
      glass: {
        0: '#ffffff',
        200: '#f1f4f9',
        300: '#e4eaf1',
        350: '#afb9c8',
        400: '#8791a0',
        500: '#587393',
        600: '#373737',
        700: '#1e1e1e',
        800: '#111112',
        900: '#0d0d0f52',
        '0h': '#edf2f7',
        '0p': '#e2ebf3',
        '200h': '#e2e9f3',
        '200p': '#d4dded',
        '300h': '#d2d7de',
        '300p': '#c9ced4',
        '600h': '#505050',
        '600p': '#646464',
        '700h': '#2e2e2e',
        '700p': '#383838',
        '800h': '#202022',
        '800p': '#2f2f32'
      },
      dsbl: {
        200: '#e4e7ec',
        300: '#b3bdca',
        400: '#828282',
        500: '#414146'
      },
      electric: {
        200: '#eeeeff',
        250: '#91cdff',
        300: '#5f9bf0',
        350: '#6b78fc',
        400: '#2137fc',
        500: '#283750',
        '200h': '#dbdbff',
        '200p': '#ced1ff',
        '250h': '#7dafdc',
        '250p': '#73a0c8',
        '300h': '#5087d2',
        '300p': '#4b78be',
        '350h': '#9ba3ff',
        '350p': '#6870cc',
        '400h': '#1d2fda',
        '400p': '#1a2bc5',
        '500h': '#465069',
        '500p': '#556478'
      },
      red: {
        200: '#ffe8e8',
        300: '#ff463c',
        400: '#fa0f19',
        500: '#5f2323',
        '300h': '#ff4d42',
        '300p': '#d83b33',
        '400h': '#ff101b',
        '400p': '#d40c15'
      },
      yellow: {
        200: '#ffeed3',
        300: '#fbb13b',
        400: '#ff9500',
        500: '#5a4015'
      },
      green: {
        200: '#d9fbdd',
        300: '#30d158',
        400: '#0fa51e',
        500: '#1e5028',
        '300h': '#28b44b',
        '300p': '#28a546',
        '400h': '#3fb74b',
        '400p': '#0c8418'
      },
      hglt: {
        200: '#e7e5ff',
        300: '#b4beff',
        400: '#3a72e0',
        500: '#374b64'
      }
    },
    'speechify-blue': {
      600: '#3a62fe',
      700: '#274de1'
    },
    glass: {
      0: '#FFFFFF',
      1: '#FCFEFE',
      100: '#F8FAFC',
      200: '#F1F4F9',
      300: '#E4EAF1',
      350: '#AFB9C8',
      400: '#778FAD',
      500: '#587393',
      600: '#364A63',
      700: '#1C2C40',
      800: '#14202E',
      900: '#05070B'
    },
    'glass-hovered': {
      0: '#E6EAEE',
      200: '#E8EFFF',
      300: '#D2D7DE'
    },
    'glass-pressed': {
      0: '#DEE5EB',
      200: '#D4DFF3',
      300: '#C9CED4'
    },
    'electric-hovered': {
      200: 'rgba(219, 219, 255, 1)',
      350: 'rgba(155, 163, 255, 1)',
      400: 'rgba(29, 47, 218, 1)',
      500: 'rgba(70, 80, 105, 1)'
    },
    'electric-pressed': {
      200: 'rgba(206, 209, 255, 1)',
      350: '#6870CC',
      400: 'rgba(26, 43, 197, 1)',
      500: 'rgba(85, 100, 120, 1)'
    },
    electric: {
      100: '#F5F6FF',
      200: '#E6E8FF',
      250: '#CDCFFF',
      300: '#6A79FD',
      350: '#6B78FC',
      400: '#2137FC',
      500: 'rgba(40, 55, 80, 1)',
      600: '#021194',
      700: '#020C65'
    },
    red: {
      100: '#FFF5F5',
      200: '#FECDCF',
      300: '#FC7377',
      400: '#FA0F17'
    },
    'red-hovered': {
      400: '#FC4A43'
    },
    'red-pressed': {
      400: '#D90E17'
    },
    yellow: {
      100: '#FFFBF5',
      200: '#FFEED3',
      300: '#FFC864',
      400: '#FFAF19',
      500: '#876E37'
    },
    green: {
      100: '#F6FEF7',
      200: '#7ECE86',
      300: '#1AB229',
      400: '#0FA41E',
      210: '#D9FBDD'
    },
    gradient: {
      500: 'linear-gradient(131deg, #0D22F3 0%, #7B00F5 100%)'
    },
    accentText: '#112d6d',
    catalina2: '#3A5D87',
    contrastBackground: '#fff',
    buttonHover: '#D2D7DE',
    facebook: '#3b5998',
    folder1: '#4067ff',
    folder5: '#6DC185',
    highlight1: '#2B9BFF',
    highlightBackground: '#E6E8FF',
    mauve0: '#031AE7',
    mauve1: '#2137FC',
    neutralTint: '#4489f6',
    optimisticTint: '#519F54',
    pageBackground: '#F1F4F9',
    pessimisticTint: '#D15151',
    primaryBackground: '#3a62fe',
    primaryText: '#2d2d2d',
    primaryTint: '#3a62ff',
    quaternaryText: '#d1d1d1',
    searchBar: '#f8fafc',
    secondaryBackground: 'rgba(58, 98, 254, .12)',
    secondaryText: '#818181',
    secondaryTint: '#878CB2',
    tertiaryBackground: 'rgba(55, 75, 155, .10)',
    tertiaryText: '#b3b3b3',
    tertiaryTint: 'rgba(135, 140, 178, .5)',
    warningTint: '#ffc31d'
  },
  fontFamily: {
    ABCDiatype: ['ABCDiatype', ...defaultTheme.fontFamily.sans],
    recoleta: ['Recoleta', ...defaultTheme.fontFamily.serif],
    sans: ['ABCDiatype', ...defaultTheme.fontFamily.sans]
  },
  gridTemplateColumns: {
    listeningNav: '1fr 2fr 1fr'
  },
  fontSize: {
    'heading-1': [
      '2.25rem',
      {
        fontWeight: '700',
        lineHeight: '2.75rem'
      }
    ],

    'heading-2': [
      '1.5rem',
      {
        fontWeight: '700',
        lineHeight: '2rem'
      }
    ],

    'heading-3': [
      '1.25rem',
      {
        fontWeight: '700',
        lineHeight: '1.75rem'
      }
    ],

    'subheading-3': [
      '1.25rem',
      {
        fontWeight: '500',
        lineHeight: '1.75rem'
      }
    ],

    'body-3': [
      '1.25rem',
      {
        fontWeight: '400',
        lineHeight: '1.75rem'
      }
    ],

    'heading-4': [
      '1.125rem',
      {
        fontWeight: '700',
        lineHeight: '1.75rem'
      }
    ],
    'subheading-4': [
      '1rem',
      {
        fontWeight: '500',
        lineHeight: '1.125rem'
      }
    ],
    'text-document': [
      '1.125rem',
      {
        fontWeight: '400',
        lineHeight: '1.75rem',
        letterSpacing: '0.01125rem'
      }
    ],
    'heading-5': [
      '1rem',
      {
        fontWeight: '700',
        lineHeight: '1.5rem',
        letterSpacing: '0.01rem'
      }
    ],
    'subheading-5': [
      '1rem',
      {
        fontWeight: '500',
        lineHeight: '1.5rem',
        letterSpacing: '0.01rem'
      }
    ],
    'body-5': [
      '1rem',
      {
        fontWeight: '400',
        lineHeight: '1.5rem'
      }
    ],
    'heading-6': [
      '0.875rem',
      {
        fontWeight: '700',
        lineHeight: '1.25rem',
        letterSpacing: '0.00875rem'
      }
    ],

    'subheading-6': [
      '0.875rem',
      {
        fontWeight: '500',
        lineHeight: '1.25rem',
        letterSpacing: '0.00875rem'
      }
    ],

    'body-6': [
      '0.875rem',
      {
        fontWeight: '400',
        lineHeight: '1.25rem',
        letterSpacing: '0.00875rem'
      }
    ],

    'subheading-7': [
      '0.75rem',
      {
        fontWeight: '500',
        lineHeight: '1rem',
        letterSpacing: '0.0075rem'
      }
    ],

    'body-7': [
      '0.75rem',
      {
        fontWeight: '400',
        lineHeight: '1rem',
        letterSpacing: '0.0075rem'
      }
    ]
  },
  maxWidth: {
    card: '360px',
    libraryCard: '220px',
    xlCard: '40rem'
  },
  width: {
    card: '360px',
    fullCard: '576px',
    xlCard: '40rem'
  },
  height: {
    checklist: '21.75rem'
  },
  zIndex: {
    2000: '2000'
  },
  borderRadius: {
    0: '0px',
    50: '2px',
    100: '4px',
    150: '6px',
    200: '8px',
    250: '10px',
    300: '12px',
    400: '16px',
    500: '20px',
    full: '9999px'
  },
  borderWidth: {
    0: '0px',
    xs: '0.5px',
    s: '1px',
    m: '1.5px',
    la: '2px'
  },
  spacing: {
    'spl-0': '0px',
    'spl-1': '2px',
    'spl-2': '4px',
    'spl-3': '6px',
    'spl-4': '8px',
    'spl-5': '10px',
    'spl-6': '12px',
    'spl-7': '16px',
    'spl-8': '20px',
    'spl-9': '24px',
    'spl-10': '28px',
    'spl-11': '32px',
    'spl-12': '36px',
    'spl-13': '40px'
  }
} satisfies Config['theme'];
