import { FirebaseApp } from 'firebase/app';
import {
  Auth as FirebaseAuth,
  AuthProvider,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthCredential,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
  User
} from 'firebase/auth';

export { OAuthCredential };

export type { User };

export class Auth {
  firebaseAuth: FirebaseAuth;

  FacebookAuthProvider = FacebookAuthProvider;

  GoogleAuthProvider = GoogleAuthProvider;

  OAuthProvider = OAuthProvider;

  OAuthCredential = OAuthCredential;

  constructor(private app: FirebaseApp) {
    this.firebaseAuth = getAuth(app);
  }

  get currentUser() {
    return this.firebaseAuth.currentUser;
  }

  authStateReady() {
    return this.firebaseAuth.authStateReady;
  }

  signInWithEmailAndPassword = (email: string, password: string) => {
    return signInWithEmailAndPassword(this.firebaseAuth, email, password);
  };

  signInAnonymously = () => {
    return signInAnonymously(this.firebaseAuth);
  };

  signInWithPopup = (provider: AuthProvider) => {
    return signInWithPopup(this.firebaseAuth, provider);
  };

  signInWithCustomToken = (token: string) => {
    return signInWithCustomToken(this.firebaseAuth, token);
  };

  onAuthStateChanged = (callback: (user: User | null) => void) => {
    return onAuthStateChanged(this.firebaseAuth, callback);
  };

  createUserWithEmailAndPassword = (email: string, password: string) => {
    return createUserWithEmailAndPassword(this.firebaseAuth, email, password);
  };

  sendPasswordResetEmail = (email: string) => {
    return sendPasswordResetEmail(this.firebaseAuth, email);
  };

  updateProfile = (profile: { displayName?: string; photoURL?: string }) => {
    return updateProfile(this.currentUser as User, profile);
  };

  signOut = () => {
    return signOut(this.firebaseAuth);
  };
}
