/**
 * This file contains the definition/mappings for the feature config (flag, A/B test, etc.) that are used in the codebase
 * for @speechifyinc/lib-feature-flag integration
 */

import { createFeatureDefinitions, createFeatureFlag, createMultiVariantsTestFeature } from '@speechifyinc/lib-feature-flag';

export enum FeatureNameEnum {
  CANVAS_INTEGRATION = 'canvasIntegration',
  DOWNLOAD_MP3 = 'webDownloadMp3',
  EXTEND_TRIAL_FOR_CANCELLING_USERS = 'extendTrialForCancellingUsers',
  GAMIFICATION = 'webAppGamification',
  GAMIFICATION_SET_GOAL_PROMPT = 'gamificationSetGoalPrompt',
  IS_DOWNLOAD_MP3_ENABLED = 'isdownloadmp3enabled',
  ML_PAGE_PARSING = 'webSdkMLPageParsing',
  NEW_EMMA_MODEL = 'newEmmaModel',
  OCR = 'ocr',
  OCR_FALLBACK_STRATEGY = 'webOcrFallbackStrategy',
  OFFER_MONTHLY_OPTION_FOR_CANCELLATION_FLOW = 'offerMonthlyOptionForCancellationFlow',
  ONE_DRIVE_INTEGRATION = 'oneDriveIntegration',
  PDF_UPSELL = 'pdfUpsell',
  REPLACE_END_SHARE = 'replaceendshare',
  REQUEST_A_FEATURE_WEB_APP = 'requestAFeatureWebApp',
  RICH_CONTENT = 'webRichContent',
  SAASQUATCH_REFERRAL = 'saasquatchReferral',
  SDK_SLIDING_WINDOW = 'webSdkSlidingWindow',
  SHOW_EXTENSION_UPSELL_ON_WEB_LINK = 'showExtensionUpsellOnWebLink',
  SKIP_HEADER_FOOTER_UPSELL = 'webSkipHeaderFooterUpsell',
  SVL_HOVER_WEB_APP_ITEM = 'svlHoverWebAppItem',
  SVL_SHARE_FROM_WEB_DOC_END = 'svlShareFromWebDocEnd',
  VOICE_CLONING = 'voiceCloningWeb',
  WA_BLACK_FRIDAY_22 = 'WABlackFriday22',
  WEB_APP_BASIC_TRIAL = 'webAppBasicTrial',
  WEB_APP_LISTENING_EXPERIENCE_OVERHAUL = 'webAppListeningExperienceOverhaul',
  WEB_APP_ZERO_STATE = 'webAppZeroState',
  WEB_SHOW_PAGINATION = 'webShowPagination',
  WEB_SIDEBAR_SUMMARY = 'webSidebarSummary',
  WEB_SUBSCRIPTION_CANCEL_FLOW_V3 = 'webSubscriptionCancelFlowV3'
}

export enum GamificationVariantEnum {
  CONTROL = 'control',
  ENABLED = 'enabled'
}

export enum IsDownloadMp3EnabledVariant {
  CONTROL = 'control',
  ENABLED = 'enabled'
}

export enum SkipHeaderFooterUpsellTestVariant {
  CONTROL = 'control',
  SHOW = 'show',
  EXCLUDED = 'excluded'
}

export enum WebSidebarSummaryTestVariant {
  CONTROL = 'control',
  ENABLED = 'enabled'
}

export enum VoiceCloningTestVariant {
  CONTROL = 'control',
  ENABLED = 'enabled'
}

export enum OcrFallbackStrategyVariant {
  CONTROL = 'control',
  EXPERIMENTAL = 'experimental'
}

export enum CanvasIntegrationTestVariant {
  CONTROL = 'control',
  ENABLED = 'enabled'
}

export enum OneDriveIntegrationTestVariant {
  CONTROL = 'control',
  ENABLED = 'enabled'
}

export enum PdfUpsellTestVariant {
  CONTROL = 'control',
  UPSELL = 'upsell',
  UPSELL_THREE = 'upsellThree'
}

export enum WebAppListeningExperienceOverhaulVariant {
  CONTROL = 'control',
  ENABLED = 'enabled'
}

export const ALL_FEATURE_DEFINITIONS = createFeatureDefinitions({
  [FeatureNameEnum.GAMIFICATION]: createMultiVariantsTestFeature().withVariants(GamificationVariantEnum.CONTROL, GamificationVariantEnum.ENABLED).build(),
  [FeatureNameEnum.SKIP_HEADER_FOOTER_UPSELL]: createMultiVariantsTestFeature()
    .withVariants(SkipHeaderFooterUpsellTestVariant.CONTROL, SkipHeaderFooterUpsellTestVariant.SHOW)
    .withCustomVariants(SkipHeaderFooterUpsellTestVariant.EXCLUDED)
    .build(),
  [FeatureNameEnum.ML_PAGE_PARSING]: createFeatureFlag(),
  [FeatureNameEnum.RICH_CONTENT]: createFeatureFlag(),
  [FeatureNameEnum.SDK_SLIDING_WINDOW]: createFeatureFlag(),
  [FeatureNameEnum.IS_DOWNLOAD_MP3_ENABLED]: createMultiVariantsTestFeature()
    .withVariants(IsDownloadMp3EnabledVariant.CONTROL, IsDownloadMp3EnabledVariant.ENABLED)
    .build(),
  [FeatureNameEnum.WEB_SIDEBAR_SUMMARY]: createMultiVariantsTestFeature()
    .withVariants(WebSidebarSummaryTestVariant.CONTROL, WebSidebarSummaryTestVariant.ENABLED)
    .build(),
  [FeatureNameEnum.VOICE_CLONING]: createMultiVariantsTestFeature().withVariants(VoiceCloningTestVariant.CONTROL, VoiceCloningTestVariant.ENABLED).build(),
  [FeatureNameEnum.OCR_FALLBACK_STRATEGY]: createMultiVariantsTestFeature()
    .withVariants(OcrFallbackStrategyVariant.CONTROL, OcrFallbackStrategyVariant.EXPERIMENTAL)
    .build(),
  [FeatureNameEnum.PDF_UPSELL]: createMultiVariantsTestFeature()
    .withVariants(PdfUpsellTestVariant.CONTROL, PdfUpsellTestVariant.UPSELL, PdfUpsellTestVariant.UPSELL_THREE)
    .build(),
  [FeatureNameEnum.CANVAS_INTEGRATION]: createMultiVariantsTestFeature()
    .withVariants(CanvasIntegrationTestVariant.CONTROL, CanvasIntegrationTestVariant.ENABLED)
    .build(),
  [FeatureNameEnum.WEB_APP_LISTENING_EXPERIENCE_OVERHAUL]: createMultiVariantsTestFeature()
    .withVariants(WebAppListeningExperienceOverhaulVariant.CONTROL, WebAppListeningExperienceOverhaulVariant.ENABLED)
    .build(),
  [FeatureNameEnum.ONE_DRIVE_INTEGRATION]: createMultiVariantsTestFeature()
    .withVariants(OneDriveIntegrationTestVariant.CONTROL, OneDriveIntegrationTestVariant.ENABLED)
    .build()
} as const);
