import type { ReadingBundle as SDKReadingBundle, Speech, SpeechSentence, SpeechView, StandardBlock, StandardView } from '@speechifyinc/multiplatform-sdk';

import type { MultiplatformSDKInstance } from '../../../sdk';
import { ClassicBlockInfo, ClassicElementInfo, LeafClassicElementInfo } from './ClassicBlockInfo';

type SentenceCacheItem =
  | {
      isLoading: true;
      promise: Promise<Speech>;
    }
  | {
      isLoading: false;
      speech: Speech;
    };

export class ClassicReaderInfo {
  private _sdkStandardBlocks: StandardBlock[] = [];
  private _classicBlocks: ClassicBlockInfo[] = [];

  private _sentencesCache: WeakMap<StandardBlock, SentenceCacheItem> = new WeakMap();

  public get classicBlocks(): ClassicBlockInfo[] {
    return this._classicBlocks;
  }

  private _initializePromise: Promise<void>;

  constructor(
    private sdk: MultiplatformSDKInstance,
    private bundle: SDKReadingBundle
  ) {
    this._initializePromise = this._init();
  }

  get standardView(): StandardView {
    return this.bundle.listeningBundle.contentBundle.standardView;
  }

  get speechView(): SpeechView {
    return this.bundle.listeningBundle.contentBundle.speechView;
  }

  private _init = async (): Promise<void> => {
    const { promisify } = this.sdk;
    const standardView = this.standardView;
    const { blocks } = await promisify(standardView.getBlocksBetweenCursors.bind(standardView))(standardView.start, standardView.end);
    this._sdkStandardBlocks = blocks;
    this._classicBlocks = blocks.map((block, blockIndex) => new ClassicBlockInfo(this.sdk, block, blockIndex));
  };

  public async initialize(): Promise<void> {
    await this._initializePromise;
  }

  public getLeafElements = (): LeafClassicElementInfo[] => {
    return this.classicBlocks.flatMap(block => {
      const thisBlockElements = block.elements.flatMap(ClassicElementInfo.collectAllLeafElements);
      return thisBlockElements;
    });
  };

  private fetchSentences = (block: StandardBlock): SentenceCacheItem => {
    if (this._sentencesCache.has(block)) {
      return this._sentencesCache.get(block)!;
    }

    const { promisify } = this.sdk;
    const { SpeechQueryBuilder, CursorQueryBuilder } = this.sdk.sdkModule;
    const sentenceQuery = SpeechQueryBuilder.fromBounds(CursorQueryBuilder.fromCursor(block.start), CursorQueryBuilder.fromCursor(block.end));
    const promise = promisify(this.speechView.getSpeech.bind(this.speechView))(sentenceQuery);
    const item = {
      isLoading: true,
      promise
    } as const;
    this._sentencesCache.set(block, item);
    promise.then(speech => {
      this._sentencesCache.set(block, { isLoading: false, speech });
    });
    return item;
  };

  public getSentences = async (blockIndex: number): Promise<SpeechSentence[]> => {
    const block = this._sdkStandardBlocks[blockIndex];
    const cacheItem = this._sentencesCache.get(block) || this.fetchSentences(block);
    const speech = cacheItem.isLoading ? await cacheItem.promise : cacheItem.speech;
    return speech.sentences;
  };

  totalBlockCount(): number {
    return this._sdkStandardBlocks.length;
  }
}
