import type { BooleanSettingKey, IndividualSettingToggleImplementation } from '../settings';
import autoHideInterface from './display/autoHideInterface';
import autoScroll from './display/autoScroll';
import clickToListen from './display/clickToListen';
import darkMode from './display/darkMode';
import textHighlighting from './display/textHighlighting';
import { toggleSkipContent } from './skipContent';

export const toggleSettingImplementationMapping: { [K in BooleanSettingKey]: IndividualSettingToggleImplementation<K> } = {
  textHighlighting,
  autoScroll,
  autoHideInterface,
  clickToListen,
  darkMode,
  headers: toggleSkipContent('headers'),
  footers: toggleSkipContent('footers'),
  footnotes: toggleSkipContent('footnotes'),
  braces: toggleSkipContent('braces'),
  citations: toggleSkipContent('citations'),
  parentheses: toggleSkipContent('parentheses'),
  brackets: toggleSkipContent('brackets'),
  urls: toggleSkipContent('urls')
};
