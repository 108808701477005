export type DisplaySettings = {
  textHighlighting: boolean;
  autoScroll: boolean;
  autoHideInterface: boolean;
  darkMode: boolean;
  clickToListen: boolean;
};

export const DEFAULT_DISPLAY_SETTINGS: DisplaySettings = {
  textHighlighting: true,
  autoScroll: true,
  autoHideInterface: true,
  darkMode: false,
  clickToListen: true
};

export type SkipContentSettings = {
  headers: boolean;
  footers: boolean;
  footnotes: boolean;
  braces: boolean;
  citations: boolean;
  parentheses: boolean;
  brackets: boolean;
  urls: boolean;
};

// Note: this is needed to backport/support the legacy listening v1 analytics to ensure the Amplitude chart remains supported
export const SKIP_CONTENT_SETTING_KEY_TO_ANALYTICS_TYPE = {
  headers: 'headers',
  footers: 'footers',
  footnotes: 'footnotes',
  braces: 'braces',
  citations: 'citations',
  parentheses: 'roundBrackets',
  brackets: 'squareBrackets',
  urls: 'urls'
} as const satisfies Record<keyof SkipContentSettings, unknown>;

export type SkipContentAnalyticsTypes = (typeof SKIP_CONTENT_SETTING_KEY_TO_ANALYTICS_TYPE)[keyof typeof SKIP_CONTENT_SETTING_KEY_TO_ANALYTICS_TYPE];

export const DEFAULT_SKIP_CONTENT_SETTINGS: SkipContentSettings = {
  headers: false,
  footers: false,
  footnotes: false,
  braces: false,
  citations: false,
  parentheses: false,
  brackets: false,
  urls: false
};

export const SKIP_CONTENT_SETTINGS_KEYS = Object.keys(DEFAULT_SKIP_CONTENT_SETTINGS) as (keyof SkipContentSettings)[];

export const DEFAULT_ZOOM_PERCENTAGE = 100;

export type ListeningSettings = {
  zoomPercentage: number;
} & DisplaySettings &
  SkipContentSettings;

export type SettingKey = keyof ListeningSettings;
export type SettingValue<K extends SettingKey> = ListeningSettings[K];

export type BooleanSettingKey = { [K in SettingKey]: ListeningSettings[K] extends boolean ? K : never }[SettingKey];

export type IndividualSettingToggleImplementation<K extends SettingKey> = (value: SettingValue<K>) => void;
