import { PSPDFKitFacade } from 'lib/pdf/pspdfkit';
import { range } from 'lodash';
import debounce from 'lodash/debounce';
import { isAutoScrollEnabled } from 'modules/listening/features/autoScroll/autoScrollStore';
import { getAutoScrollAnchor } from 'modules/listening/features/autoScroll/utils';
import { pdfStoreActions } from 'modules/listening/features/reader/stores/pdfStore';
import { renderedContentStoreSelectors } from 'modules/listening/features/reader/stores/renderedContentStore';

import type {
  BookPage,
  BookPageTextContentItem,
  ContentBundle,
  ContentCursor,
  ContentOverlayRange,
  CurrentWordAndSentenceOverlayEvent,
  CurrentWordAndSentenceOverlayHelper,
  ReadingBundle as SDKReadingBundle,
  RenderedContentOverlayProvider,
  SpeechSentence
} from '@speechifyinc/multiplatform-sdk';

import type { MultiplatformSDKInstance } from '../../sdk';
import { PlaybackInfo } from '../playback';
import { HighlightingInfo, OverlayInfo, PlaybackCursorPosition, SentenceMark } from './base';
import { getRelativeRect } from './utils';

export enum PDFTextItemType {
  DIGITAL_TEXT = 'DIGITAL_TEXT',
  IMAGE = 'IMAGE'
}

export type PDFTextContentItemInfo = {
  pageIndex: number;
  customId: string;
  item: BookPageTextContentItem;
  viewport: {
    width: number;
    height: number;
  };
  type: PDFTextItemType;
};

type PDFOverlayContentRef = PDFTextContentItemInfo;

export class PDFOverlayInfo extends OverlayInfo<PDFOverlayContentRef> {
  private _cleanUpFunctions: (() => void)[] = [];
  private _overlayProvider: RenderedContentOverlayProvider<PDFOverlayContentRef>;
  private _overlayHelper: CurrentWordAndSentenceOverlayHelper<PDFOverlayContentRef>;

  private _bookPageCacheByPageIndex: Map<number, BookPage> = new Map();
  private _bookPageTextContentItemCacheByPageIndex: Map<number, PDFTextContentItemInfo[]> = new Map();

  private _isCurrentlyAutoScrolling = false;

  constructor(
    public readonly sdk: MultiplatformSDKInstance,
    public readonly bundle: SDKReadingBundle,
    public readonly playbackInfo: PlaybackInfo
  ) {
    super();
    this._overlayProvider = new sdk.sdkModule.RenderedContentOverlayProvider<PDFOverlayContentRef>();
    this._overlayHelper = new sdk.sdkModule.CurrentWordAndSentenceOverlayHelper<PDFOverlayContentRef>(bundle.playbackControls, this._overlayProvider);
  }

  get bookView() {
    const bundle = this.bundle;
    return (bundle.listeningBundle.contentBundle as ContentBundle.BookBundle).bookView;
  }

  get speechView() {
    return this.bundle.listeningBundle.contentBundle.speechView;
  }

  get pageCount() {
    return this.bookView.getMetadata().numberOfPages;
  }

  public getPageTextContentItems = (pageIndex: number): PDFTextContentItemInfo[] => {
    return this._bookPageTextContentItemCacheByPageIndex.get(pageIndex) || [];
  };

  public getDigitalTextItemsForPageIndex = (pageIndex: number): PDFTextContentItemInfo[] => {
    return this.getPageTextContentItems(pageIndex).filter(item => item.type === PDFTextItemType.DIGITAL_TEXT);
  };

  public getImageItemsForPageIndex = (pageIndex: number): PDFTextContentItemInfo[] => {
    return this.getPageTextContentItems(pageIndex).filter(item => item.type === PDFTextItemType.IMAGE);
  };

  private getSdkBookPage = async (pageIndex: number) => {
    if (this._bookPageCacheByPageIndex.has(pageIndex)) {
      return this._bookPageCacheByPageIndex.get(pageIndex)!;
    }
    const { promisify } = this.sdk;
    const { bookView } = this;
    const [sdkBookPage] = await promisify(bookView.getPages.bind(bookView))([pageIndex]);
    this._bookPageCacheByPageIndex.set(pageIndex, sdkBookPage);
    return sdkBookPage;
  };

  private getTextContentsForPageIndex = async (pageIndex: number) => {
    if (this._bookPageTextContentItemCacheByPageIndex.has(pageIndex)) {
      return this._bookPageTextContentItemCacheByPageIndex.get(pageIndex)!;
    }
    const { promisify } = this.sdk;
    const sdkBookPage = await this.getSdkBookPage(pageIndex);
    const sdkTextContent = await promisify(sdkBookPage.getTextContent.bind(sdkBookPage))();

    const viewport = sdkBookPage.getMetadata().viewport;

    const customTextContents = sdkTextContent.map((item, index) => {
      if (item.textSourceType.name === 'DIGITAL_TEXT') {
        return {
          pageIndex: pageIndex,
          customId: `custom_${pageIndex}_${index}`,
          type: PDFTextItemType.DIGITAL_TEXT,
          viewport,
          item
        };
      }
      return {
        pageIndex: pageIndex,
        customId: `custom_${pageIndex}_${index}`,
        item,
        viewport,
        type: PDFTextItemType.IMAGE
      };
    });

    this._bookPageTextContentItemCacheByPageIndex.set(pageIndex, customTextContents);
    return customTextContents;
  };

  override get overlayProvider(): RenderedContentOverlayProvider<PDFOverlayContentRef> {
    return this._overlayProvider;
  }
  override get overlayHelper(): CurrentWordAndSentenceOverlayHelper<PDFOverlayContentRef> {
    return this._overlayHelper;
  }

  override overlayRangeToRects = (range: ContentOverlayRange<PDFOverlayContentRef>, baseRect: DOMRect): DOMRect[] => {
    const { startIndex, endIndex } = range;

    const ref = range.ref.value;
    const element = renderedContentStoreSelectors.getRenderedContent(ref.customId);

    if (!element) {
      return [];
    }

    const parent = element;

    const textNode = element.childNodes[0];
    const r = new Range();

    const actualStartIndex = Math.min(startIndex, textNode.textContent!.length);
    const actualEndIndex = Math.min(endIndex, textNode.textContent!.length);
    r.setStart(textNode, actualStartIndex);
    r.setEnd(textNode, actualEndIndex);

    return Array.from(r.getClientRects()).map(rect => {
      const intersectingRect = parent.getClientRects()[0];
      return getRelativeRect(baseRect, new DOMRect(rect.left, intersectingRect.top, rect.width, intersectingRect.height));
    });
  };

  // runtime-safe utility function to get [prevPage, currentPage, nextPage]
  private _getRelevantPages = (pageIndex: number): number[] => {
    const numberOfPages = this.bookView.getMetadata().numberOfPages;

    const prevPage = Math.max(0, pageIndex - 1);
    const nextPage = Math.min(numberOfPages - 1, pageIndex + 1);

    const newPageNumbersInView = new Set<number>(range(prevPage, nextPage + 1));

    const relevantPages = Array.from(newPageNumbersInView);

    // Ensure the current page is always included first
    return relevantPages.includes(pageIndex) ? [pageIndex, ...relevantPages.filter(page => page !== pageIndex)] : relevantPages;
  };

  addRenderedContentToOverlayProvider = async () => {
    const overlayProvider = this.overlayProvider;

    let relevantPages: number[] = [];

    const getPageIndexFromCursor = (cursor: ContentCursor) => {
      return this.bookView.getPageIndex(cursor);
    };

    let currentCursorPageIndex = getPageIndexFromCursor(this.playbackInfo.latestState.latestPlaybackCursor);
    let currentScrollPageIndex = PSPDFKitFacade.singleton.getCurrentPageIndex()!;

    const { sdkModule: sdk } = this.sdk;
    const ObjectRef = sdk.ObjectRef;

    const addRenderedContentForPagesInView = () => {
      relevantPages = [...new Set([...this._getRelevantPages(currentScrollPageIndex), currentCursorPageIndex])];

      const readyPages = relevantPages.filter(pageIndex => this._bookPageTextContentItemCacheByPageIndex.has(pageIndex));
      const pendingPages = relevantPages.filter(pageIndex => !this._bookPageTextContentItemCacheByPageIndex.has(pageIndex));

      pdfStoreActions.updatePagesInView(readyPages);

      pendingPages.forEach(async pageIndex => {
        try {
          const textContents = await this.getTextContentsForPageIndex(pageIndex);
          pdfStoreActions.addPagesInView([pageIndex]);
          textContents.forEach(textContent => {
            overlayProvider.addRenderedContent(new ObjectRef(textContent), textContent.item.text);
          });
        } catch (e) {
          console.error('Error while fetching page', pageIndex, e);
        }
      });
    };

    const removeStateListener = this.playbackInfo.addStateListener(playbackState => {
      const statePageIndex = getPageIndexFromCursor(playbackState.latestPlaybackCursor);
      if (statePageIndex !== currentCursorPageIndex) {
        currentCursorPageIndex = statePageIndex;
        addRenderedContentForPagesInView();
      }
    });

    const removePageIndexListener = PSPDFKitFacade.singleton.addPageIndexListener(
      debounce(pageIndex => {
        if (pageIndex !== currentScrollPageIndex) {
          currentScrollPageIndex = pageIndex;
          addRenderedContentForPagesInView();
        }
      }, 250)
    );

    try {
      addRenderedContentForPagesInView();
    } catch (e) {
      // ignore
      // TODO(overhaul): Handle this error properly
    }

    // TODO(albertusdev): Consider if we should eagerly load all pages one-by-one and build the rendered content cache
    // for better performance (with memory trade off) because calling SDK getTextContent can cause ~50ms delay per page even on a fast device
    // so loading the pages on-demand can cause highlighting and auto scroll delay if user do fast/quick scroll through the pages.

    this._cleanUpFunctions.push(removeStateListener);
    removePageIndexListener && this._cleanUpFunctions.push(removePageIndexListener);
  };

  // TODO(albertusdev): Consider refactoring this into abstract class which simply calls `highlightContainer.getClientRects()[0]`
  override getBaseRect(): DOMRect {
    return PSPDFKitFacade.singleton.instanceHighlightContainer.getClientRects()[0];
  }

  override get scroller(): HTMLElement {
    return PSPDFKitFacade.singleton.instanceScroller;
  }

  override get highlightContainer(): HTMLElement {
    return PSPDFKitFacade.singleton.instanceHighlightContainer;
  }

  override getActiveSentenceTopPositionForAutoScroll = async (sentenceOverlayRanges: ContentOverlayRange<PDFOverlayContentRef>[]) => {
    if (sentenceOverlayRanges.length === 0) {
      return null;
    }

    const firstSentence = sentenceOverlayRanges[0];

    const activeSentencePageIndex = firstSentence.ref.value.pageIndex;

    await this.maybeScrollToPageIndex(
      activeSentencePageIndex,
      /*respectAutoScroll*/ false // this function is called for the initial open of the doc, where we want to force auto scroll to last position
    );

    const baseRect = this.getBaseRect();
    const rects = this.overlayRangeToRects(firstSentence, baseRect);
    if (rects.length === 0) {
      return null;
    }

    return rects[0].top;
  };

  private maybeScrollToPageIndex = async (pageIndex: number, respectAutoScroll = true) => {
    if (respectAutoScroll && !isAutoScrollEnabled()) return;
    if (respectAutoScroll && this._isCurrentlyAutoScrolling) {
      return;
    }

    const currentPageIndex = PSPDFKitFacade.singleton.getCurrentPageIndex()!;

    const diff = Math.abs(currentPageIndex - pageIndex);
    if (diff > 1 || !PSPDFKitFacade.singleton.isPageLoaded(pageIndex)) {
      this._isCurrentlyAutoScrolling = true;
      const _lastAutoScrollDelayPromise = new Promise(resolve => {
        PSPDFKitFacade.singleton.goToPage(pageIndex);
        // wait a little bit for scroll animation to finish and React to render the overlay
        setTimeout(resolve, 500);
      });
      await _lastAutoScrollDelayPromise;
      this._isCurrentlyAutoScrolling = false;
    }
  };

  private _latestHighlightingEvent: CurrentWordAndSentenceOverlayEvent<PDFOverlayContentRef> | null = null;
  override listenToCurrentWordAndSentenceHighlighting = (callback: (currentWordAndSentence: HighlightingInfo) => void): (() => void) => {
    const overlayHelper = this.overlayHelper;

    let currentAbortController = new AbortController();

    const mapOverlayEventToCurrentWordAndSentence = async (event: CurrentWordAndSentenceOverlayEvent<PDFOverlayContentRef>) => {
      try {
        const abortController = currentAbortController.signal;
        const { sentenceOverlayRanges, wordOverlayRanges } = event;
        if (wordOverlayRanges.length === 0) {
          return;
        }
        const refValue = wordOverlayRanges[0].ref.value;
        const pageIndex = refValue.pageIndex;

        await this.maybeScrollToPageIndex(pageIndex);
        if (abortController.aborted) {
          throw new Error('Aborted in favor of new highlighting event');
        }

        const baseRect = this.getBaseRect();
        const wordRects = wordOverlayRanges.flatMap(wordRange => {
          const rects = this.overlayRangeToRects(wordRange, baseRect);
          return rects;
        });

        const sentencesRects = sentenceOverlayRanges.flatMap(sentenceRange => {
          const rects = this.overlayRangeToRects(sentenceRange, baseRect);
          return rects;
        });

        const result = {
          word: { rects: wordRects },
          sentence: { rects: sentencesRects }
        };

        return result;
      } catch (e) {
        return { word: { texts: [], rects: [] }, sentence: { texts: [], rects: [] } };
      }
    };

    const processLatestHighlightingEvent = async () => {
      if (!this._latestHighlightingEvent) return;
      const abortController = currentAbortController.signal;
      const highlightingInfo = await mapOverlayEventToCurrentWordAndSentence(this._latestHighlightingEvent);
      !abortController.aborted && highlightingInfo && callback(highlightingInfo);
    };

    const cleanUp = overlayHelper.addEventListener(event => {
      currentAbortController.abort();
      this._latestHighlightingEvent = event;
      currentAbortController = new AbortController();
      processLatestHighlightingEvent();
    });

    const cleanUpCustomZoomListener = this.customHighlightingEventEmitter.addEventListener('zoom', processLatestHighlightingEvent);

    this._cleanUpFunctions.push(cleanUp);
    this._cleanUpFunctions.push(cleanUpCustomZoomListener);

    return () => {
      cleanUp();
      this._cleanUpFunctions = this._cleanUpFunctions.filter(f => f !== cleanUp && f !== cleanUpCustomZoomListener);
    };
  };

  private _speechCache: Map<number, SpeechSentence[]> = new Map();

  private async getCachedSpeech(pageIndex: number): Promise<SpeechSentence[]> {
    if (this._speechCache.has(pageIndex)) {
      return this._speechCache.get(pageIndex)!;
    }

    const { sdk, speechView } = this;
    const { promisify } = sdk;
    const getSpeech = promisify(speechView.getSpeech.bind(speechView));
    const { CursorQueryBuilder, SpeechQueryBuilder } = sdk.sdkModule;

    const bookPage = await this.getSdkBookPage(pageIndex);
    const speechQuery = SpeechQueryBuilder.fromBounds(CursorQueryBuilder.fromCursor(bookPage.start), CursorQueryBuilder.fromCursor(bookPage.end));

    const sentencesInPageIndex = await getSpeech(speechQuery);
    this._speechCache.set(pageIndex, sentencesInPageIndex.sentences);

    return sentencesInPageIndex.sentences;
  }

  private sentenceToSentenceMark = (sentence: SpeechSentence): SentenceMark => {
    const overlayRanges = this.overlayProvider.getOverlayRanges(sentence.text);
    return {
      playFromHere: () => {
        return this.playbackInfo.playFromQuery(this.sdk.sdkModule.CursorQueryBuilder.fromCursor(sentence.text.start));
      },
      rects: overlayRanges.flatMap(range => this.overlayRangeToRects(range, this.getBaseRect()))
    };
  };

  protected getSentenceMarks = async (currentPageIndex: number): Promise<SentenceMark[]> => {
    const sentences = await this.getCachedSpeech(currentPageIndex);
    return sentences.map(this.sentenceToSentenceMark);
  };

  override getRelevantClickToListenSentenceMark = async (x: number, y: number): Promise<SentenceMark | null> => {
    const currentPageIndex = PSPDFKitFacade.singleton.getCurrentPageIndex()!;
    for (const pageIndex of this._getRelevantPages(currentPageIndex)) {
      const sentences = await this.getCachedSpeech(pageIndex);

      for (const sentence of sentences) {
        const overlayRanges = this.overlayProvider.getOverlayRanges(sentence.text);
        const rects = overlayRanges.flatMap(range => this.overlayRangeToRects(range, new DOMRect(0, 0, 0, 0)));

        if (rects.some(rect => x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom)) {
          return this.sentenceToSentenceMark(sentence);
        }
      }
    }
    return null;
  };

  override getCurrentPlaybackCursorPosition = (): PlaybackCursorPosition => {
    if (!this._latestHighlightingEvent) {
      return 'notFound';
    }
    if (this._latestHighlightingEvent.sentenceOverlayRanges.length === 0) {
      return 'notFound';
    }
    const currentSentencePageIndex = this._latestHighlightingEvent.sentenceOverlayRanges[0].ref.value.pageIndex;

    const currentVisiblePageIndex = PSPDFKitFacade.singleton.getCurrentPageIndex()!;
    if (currentSentencePageIndex < currentVisiblePageIndex) {
      return 'above';
    }
    if (currentSentencePageIndex > currentVisiblePageIndex) {
      return 'below';
    }

    // Simply check if the current sentence line is above or below the center of the screen
    const currentSentenceLineElement = renderedContentStoreSelectors.getRenderedContent(
      this._latestHighlightingEvent.sentenceOverlayRanges[0].ref.value.customId
    );
    if (!currentSentenceLineElement) return 'notFound';
    const rect = currentSentenceLineElement.getBoundingClientRect();
    const autoScrollAnchor = getAutoScrollAnchor();
    if (rect.top < autoScrollAnchor) return 'above';
    return 'below';
  };

  override destroy(): void {
    this._cleanUpFunctions.forEach(f => f());
    this._cleanUpFunctions = [];
  }
}
