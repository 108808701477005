import assert from 'assert';
import { isCorsAllowed, wrapWithCorsProxy } from 'lib/speechify/adaptors/cors';
import { Nullable } from 'utils/types';

import type { LibraryItem as SDKLibraryItem } from '@speechifyinc/multiplatform-sdk';

import { BaseListenableContent, ContentMetaType, ContentType } from '../base';

export abstract class BaseItemListenableContent extends BaseListenableContent {
  public readonly itemId: string;
  public readonly parentFolderId: string;
  private _initialTitle: string;
  public readonly coverImageUrl: Nullable<string>;
  public readonly sourceUrl: Nullable<string> = null;
  public readonly originalSourceUrl: Nullable<string> = null;
  public readonly contentType: ContentType = ContentType.libraryItem;
  public readonly totalWords: number;

  constructor(item: SDKLibraryItem.Content) {
    super();
    this.parentFolderId = item.parentFolderId;
    this.itemId = item.uri.id;
    this._initialTitle = item.title;
    this.coverImageUrl = item.coverImageUrl;
    this.sourceUrl = item.sourceStoredUrl || item.sourceUrl;
    this.originalSourceUrl = item.sourceUrl;
    this.totalWords = item.totalWords ?? 0;
  }

  abstract get metaType(): ContentMetaType;

  content = async (): Promise<string> => {
    const url = this.sourceUrl;
    assert(url, 'PDFItemListenableContent: sourceUrl is null');
    if (isCorsAllowed(url)) return url;
    return wrapWithCorsProxy(url);
  };

  public get title(): string {
    return this._titleOverride || this._initialTitle;
  }

  public get wordCount(): number {
    return this.totalWords;
  }

  override onItemIdReady(): void {
    // no-op
  }
}
