import React, { CSSProperties, useRef } from 'react';

import { handleOnSearchIconClick } from 'modules/listening/features/search';
import { useActiveTab } from 'modules/listening/hooks/useActiveTab';
import { useFileActionV2 } from 'modules/listening/hooks/useFileAction';
import { UseListenableContentItemIdState } from 'modules/listening/hooks/useListenableContentItemId';
import { useSearchKeyboardShortcut } from 'modules/listening/hooks/useSearchKeyboardShortcut';
import { navStoreActions } from 'modules/listening/navStore';
import { SLIDE_TRANSITION_DURATION_IN_MS } from 'modules/listening/utils/constants';
import { useLatestListenableContentTitle } from 'modules/sdk/hooks/useLatestListenableContentTitle';
import { ListenableContent } from 'modules/sdk/lib';
import { cn } from 'utils/cn';

import ChevronDownIcon from '../icons/ChevronDownIcon';
import SearchIcon from '../icons/SearchIcon';
import { PageControls, PageControlsProps } from '../PageControls';
import { SCROLLBAR_WIDTH_IN_PX } from '../shared/constants';
import FileAction from './FileAction';
import AIIcon from './icons/AIIcon';
import BackIcon from './icons/BackIcon';
import DownloadMp3Icon from './icons/DownloadMp3Icon';
import SettingsIcon from './icons/SettingsIcon';
import TOCIcon from './icons/TOCIcon';
import NavIcon from './NavIcon';

export type NavProps = {
  itemIdState: UseListenableContentItemIdState;
  listenableContent: ListenableContent;
  onBackArrowClick?: () => void;
  showAskAiIcon?: boolean;
  showEditIcon?: boolean;
  showDownloadMp3Icon?: boolean;
  isVisible?: boolean;
} & PageControlsProps;

export const TOP_NAV_HEIGHT_IN_PX = 53;
const getFadeStyle = (isVisible: boolean): CSSProperties => ({
  opacity: isVisible ? 1 : 0,
  transition: 'opacity 0.3s',
  pointerEvents: isVisible ? 'auto' : 'none'
});

const BACKGROUND_AND_BORDER_CLASS_NAMES = 'bg-bg-primary border-b-s border-border-primary';

const Nav = ({
  isVisible,
  listenableContent,
  itemIdState,
  onBackArrowClick,
  showAskAiIcon,
  showEditIcon,
  showDownloadMp3Icon,
  ...paginationControlsProps
}: NavProps) => {
  useSearchKeyboardShortcut();

  const { activeTab, handleTabClick } = useActiveTab(paginationControlsProps.isPdf);
  const title = useLatestListenableContentTitle(listenableContent);

  const menuRef = useRef<HTMLButtonElement>(null);

  const { showFileAction, onFileNameClick, onMenuLeave, onActionClick } = useFileActionV2({
    menuRef,
    itemIdState
  });

  return (
    <>
      <nav
        className="fixed grid z-10"
        style={{
          height: `${TOP_NAV_HEIGHT_IN_PX}px`,
          width: `calc(100% - ${SCROLLBAR_WIDTH_IN_PX}px)`,
          gridTemplateColumns: `1fr 2fr 1fr`,
          ...getFadeStyle(!!isVisible)
        }}
      >
        <div className={cn(BACKGROUND_AND_BORDER_CLASS_NAMES, 'px-1 py-2 flex items-center gap-2')}>
          <NavIcon icon={<BackIcon />} onClick={onBackArrowClick} />
          <div className="w-[1px] h-6 bg-border-primary" />
          {showAskAiIcon ? (
            <NavIcon
              onClick={() => {
                handleTabClick('ask-ai');
                setTimeout(() => {
                  const askAiInput = document.querySelector<HTMLInputElement>('.ask-ai-input-focus');
                  askAiInput?.focus();
                }, SLIDE_TRANSITION_DURATION_IN_MS);
              }}
              active={activeTab === 'ask-ai'}
              icon={
                <AIIcon className="[&>#ai-icon-gradient]:fill-[url(#ai_icon_light_gradient)] dark:[&>#ai-icon-gradient]:fill-[url(#ai_icon_dark_gradient)]" />
              }
            />
          ) : null}

          {paginationControlsProps.isPdf && (
            <NavIcon active={activeTab === 'navigator'} icon={<TOCIcon />} onClick={() => navStoreActions.toggleActiveTab('navigator')} />
          )}
        </div>
        <div className={cn(BACKGROUND_AND_BORDER_CLASS_NAMES, 'px-1 py-2 flex items-center justify-center gap-spl-2 relative')}>
          <button
            ref={menuRef}
            className="text-heading-6 text-text-primary cursor-pointer relative max-w-[344px] flex items-center hover:bg-surface-primary-hovered rounded-lg py-2 px-3 focus-visible:outline-2 focus-visible:outline focus-visible:outline-[#1D76ED]"
            onClick={onFileNameClick}
            onMouseLeave={onMenuLeave}
          >
            <div className="text-ellipsis whitespace-nowrap overflow-hidden max-w-[324px]">{title}</div>
            {itemIdState.isLoading ? null : <ChevronDownIcon className="w-4 h-4 ml-1 flex-shrink-0" />}

            {showFileAction && (
              <div className="absolute left-1/2 transform -translate-x-1/2 z-10 top-full -mt-9" onMouseLeave={onMenuLeave}>
                <FileAction listenableContent={listenableContent} onActionClick={onActionClick} showDownloadMp3={showDownloadMp3Icon} />
              </div>
            )}
          </button>

          {showDownloadMp3Icon ? <NavIcon onClick={() => navStoreActions.setModalState('downloadMp3')} icon={<DownloadMp3Icon />} /> : null}
        </div>
        <div className={cn(BACKGROUND_AND_BORDER_CLASS_NAMES, 'px-1 py-2 flex justify-end gap-2 items-center')}>
          {paginationControlsProps.isPdf && <NavIcon icon={<SearchIcon />} active={activeTab === 'search'} onClick={handleOnSearchIconClick} />}
          <NavIcon
            className={cn(!paginationControlsProps.isPdf && 'mr-spl-6')}
            icon={<SettingsIcon />}
            active={activeTab === 'settings'}
            onClick={() => navStoreActions.toggleActiveTab('settings')}
          />
          {paginationControlsProps.isPdf && <div className="h-spl-9 w-[1px] bg-border-primary" />}

          <PageControls {...paginationControlsProps} />
        </div>
      </nav>
      {/* Scrollbar space needs to have different z-index here */}
      <div
        className={cn('fixed right-0', BACKGROUND_AND_BORDER_CLASS_NAMES)}
        style={{
          zIndex: 5,
          width: `${SCROLLBAR_WIDTH_IN_PX}px`,
          height: `${TOP_NAV_HEIGHT_IN_PX}px`,
          ...getFadeStyle(!!isVisible)
        }}
      />
    </>
  );
};

export default Nav;
