import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import type { ListeningDependencies } from 'modules/sdk/listeningDependencies';
import { tryQueueCsatBanner } from 'modules/sideBanner/stores/csatActions';
import { store } from 'store';

import { subscriptionStoreSelectors } from '../selectors';
import { logHdWordsListened } from './logHdWordsListened';
import { switchToFree } from './switchToFree';

let cleanUpPreviousWordTracker: () => void = () => {};

export const startWordTracker = (listeningDependencies: ListeningDependencies) => {
  cleanUpPreviousWordTracker();

  let lastCount = 0;

  const getCurrentCount = () => Number.parseInt(localStorage.getItem('currentWordCount') ?? '0', 10);
  let currentCount = getCurrentCount();
  const updateCurrentCount = (count: number) => {
    currentCount = count;
    localStorage.setItem('currentWordCount', count.toString());
  };

  cleanUpPreviousWordTracker = listeningDependencies.playbackInfo.addWordsListenedListener(event => {
    if (!event) {
      return;
    }

    const { count, voice, speedInRelativeFactor, speedInWPM } = event;

    const wordsListenedDiff = Math.abs(count - lastCount);
    currentCount = getCurrentCount();
    if (currentCount > 1_000) {
      updateCurrentCount(0);
    }

    if (wordsListenedDiff >= 20) {
      lastCount = count;
      updateCurrentCount(currentCount + 20);
    }

    // log on every 500 words to segment
    if (currentCount !== 0 && (currentCount + wordsListenedDiff) % 500 === 0) {
      // logAnalyticsEvent('wordCount', { voice, count: currentCount + wordsListenedDiff });
      logAnalyticsEvent(AnalyticsEventKey.usageWordsListened, {
        is_highlighting_enabled: true,
        isEmbedded: false, // TODO(albertusdev): Implement ChatAi word tracking properly on useWordsLeft by specifying isOverhaul
        isHD: voice.isPremium,
        voice_id: voice.id,
        speed: speedInRelativeFactor,
        isClonedVoice: Boolean(voice.isCustomVoice)
      });
    }

    // log on every 100 words to segment for gamification
    if (currentCount !== 0 && (currentCount + wordsListenedDiff) % 100 === 0) {
      logAnalyticsEvent(AnalyticsEventKey.usageWordsListenedGamification, {
        is_highlighting_enabled: true,
        isHD: voice.isPremium,
        voice_id: voice.id,
        threshold: 100,
        wpm: speedInWPM,
        isGamificationEnabled: store.getState().gamification?.isEnabled ? true : false,
        isClonedVoice: Boolean(voice.isCustomVoice)
      });
    }

    if (voice.isPremium && wordsListenedDiff === 20) {
      logHdWordsListened(20);
    }

    tryQueueCsatBanner(currentCount);

    // Note: it's important we check for hasHdWordsLeft after we logHdWordsListened because it will decrement the count
    const hasHdWordsLeft = subscriptionStoreSelectors.getHasHdWords();
    // undefined on hasHDWordsLeft means store is still loading
    if (hasHdWordsLeft !== undefined && !hasHdWordsLeft && voice.isPremium) {
      // switch to free logic here.
      switchToFree();
    }
  });
};
