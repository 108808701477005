import type { LibraryItem as SDKLibraryItem } from '@speechifyinc/multiplatform-sdk';

import { ContentMetaType } from '../../base';
import { BaseItemListenableContent } from '../BaseItemListenableContent';

export class DOCXItemListenableContent extends BaseItemListenableContent {
  public readonly metaType: ContentMetaType = ContentMetaType.DOCX;
  constructor(item: SDKLibraryItem.Content) {
    super(item);
  }
}
