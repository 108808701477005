// The following file is a rewritten version of the src/utils/pspdfkit.js for the new Listening Experience V2 screen
// We are rewriting it primarily for simplicity and type safety since the previous file was written using js

import { SCROLLBAR_WIDTH_IN_PX } from 'components/newListeningExperience/shared/constants';
import { TOP_NAV_HEIGHT_IN_PX } from 'components/newListeningExperience/topnav/Nav';
import { logError } from 'lib/observability';
import { PDFListenableContent } from 'modules/sdk/lib/facade/listenableContent/types';
import type { Configuration, Instance, Size, StandaloneConfiguration, ViewState } from 'pspdfkit';

// TODO: We now have 2 constants file and this is not ideal, refactor the one on the global level.
import { PSPDFKit_KEY } from '../../constants';
import { CDN_PSPDFKIT_BASE_URL } from './constants';

async function importPSPDFKit() {
  return import(/* webpackChunkName: 'lib-pspdfkit' */ 'pspdfkit').then(module => module.default);
}

export const DEFAULT_PDF_INITIAL_ZOOM = 1.6;

export type RenderPageCallback = (context: CanvasRenderingContext2D, pageIndex: number, pageSize: Size) => unknown;

export type PageVisibilityChangedCallback = (visible: boolean) => unknown;

export type PSPDFKitServiceState = {
  instance?: Instance;
  // This contains the 'pspdfkit' module since one imports directly contributes to ~2MB bundle size
  // So we should reuse the module if it's already loaded
  lib: Awaited<ReturnType<typeof importPSPDFKit>>;
};

export type PSPDFKitServiceListener = ({ instance, lib }: PSPDFKitServiceState) => void;

type ThumbnailCacheKey = string; // `${pageIndex}-${height}`;

export class PSPDFKitFacade {
  // Create a singleton called service
  private static _singleton: PSPDFKitFacade;

  static get singleton() {
    if (!PSPDFKitFacade._singleton) {
      PSPDFKitFacade._singleton = new PSPDFKitFacade();
    }

    return PSPDFKitFacade._singleton;
  }

  static get instanceReady() {
    return new Promise<Instance>(resolve => {
      if (PSPDFKitFacade.singleton.instance) {
        resolve(PSPDFKitFacade.singleton.instance);
        return;
      }

      const unsubscribe = PSPDFKitFacade.singleton.addInstanceChangedListener(state => {
        if (state.instance) {
          unsubscribe();
          resolve(state.instance!);
        }
      });
    });
  }

  private _state: PSPDFKitServiceState | null;
  private _instanceChangedListeners: PSPDFKitServiceListener[];

  private _pageRenderListeners: RenderPageCallback[] = [];
  private _instanceCleanUpFunctions: (() => void)[] = [];

  constructor() {
    this._state = null;
    this._instanceChangedListeners = [];
  }

  private _instanceHighlightContainer: HTMLElement | null = null;
  private _instanceScrollElement: HTMLElement | null = null;
  private _instanceViewport: HTMLElement | null = null;
  private _instanceZoomElement: HTMLElement | null = null;
  private _thumbnailCache: Map<ThumbnailCacheKey, string> | null = null;

  private _onInstanceLoad = (instance: Instance) => {
    const instanceScrollElement = this._querySelector('.PSPDFKit-Scroll', instance, true);
    const instanceViewport = this._querySelector('.PSPDFKit-Viewport', instance, true);
    const instanceZoomElement = this._querySelector('.PSPDFKit-Zoom', instance, true);

    instanceZoomElement.style.position = 'relative';

    const instanceHighlightContainer = document.createElement('div');
    instanceHighlightContainer.style.position = 'absolute';
    instanceHighlightContainer.style.top = '0';
    instanceHighlightContainer.style.left = '0';
    instanceHighlightContainer.style.width = '100%';
    instanceHighlightContainer.style.height = '100%';
    instanceHighlightContainer.id = 'speechify-highlight-container';

    instanceZoomElement.appendChild(instanceHighlightContainer);

    this._instanceHighlightContainer = instanceHighlightContainer;
    this._instanceScrollElement = instanceScrollElement;
    this._instanceViewport = instanceViewport;
    this._instanceZoomElement = instanceZoomElement;

    this.containerElement.style.setProperty('--speechify-nav-height', `${TOP_NAV_HEIGHT_IN_PX}px`);
    this.containerElement.style.setProperty('--content-margin-top', `${TOP_NAV_HEIGHT_IN_PX + 12}px`);
    this.containerElement.style.setProperty('--scrollbar-width', `${SCROLLBAR_WIDTH_IN_PX}px`);

    this._instanceCleanUpFunctions = [];

    this._thumbnailCache = new Map();
  };

  private _querySelector = (selector: string, instance?: Instance, throwError?: boolean): HTMLElement => {
    const element = (instance ?? this.instance)?.contentDocument.querySelector(selector);

    if (!element && throwError) {
      throw new Error(`PSPDFKit _onInstanceLoad.querySelector('${selector}') not found`);
    }

    return element as HTMLElement;
  };

  public get instanceHighlightContainer() {
    if (!this._instanceHighlightContainer) {
      throw new Error('PSPDFKit instanceHighlightContainer not found');
    }

    return this._instanceHighlightContainer;
  }

  public get instanceScroller() {
    if (!this._instanceScrollElement) {
      throw new Error('PSPDFKit instanceScroller not found');
    }
    return this._instanceScrollElement;
  }

  public defaultConfiguration(lib: PSPDFKitServiceState['lib']): Partial<StandaloneConfiguration> {
    return {
      baseUrl: CDN_PSPDFKIT_BASE_URL,
      initialViewState: new lib.ViewState({
        currentPageIndex: 0,
        showToolbar: false,
        zoom: DEFAULT_PDF_INITIAL_ZOOM
      }),
      licenseKey: PSPDFKit_KEY,
      theme: lib.Theme.AUTO,
      styleSheets: ['/css/customPSPDFKit.css'],
      disableTextSelection: true,
      disableMultiSelection: true
    };
  }

  public preload = async (configuration: Partial<StandaloneConfiguration> = {}) => {
    const lib = await importPSPDFKit();
    this._onChange({ lib });
    await lib.preloadWorker({
      ...this.defaultConfiguration(lib),
      ...configuration
    } as StandaloneConfiguration);
  };

  public load = async (
    configuration: {
      container: HTMLElement;
      listenableContent: PDFListenableContent;
    } & Partial<Omit<Configuration, 'document'>>
  ) => {
    const [lib, document] = await Promise.all([this.lib ?? importPSPDFKit(), configuration.listenableContent.content()]);

    const instance = await lib.load({
      ...this.defaultConfiguration(lib),
      ...configuration,
      renderPageCallback: this._renderPageCallback,
      document,
      disableTextSelection: true
    });

    this._onInstanceLoad(instance);
    this._onChange({ instance, lib: lib });

    return instance;
  };

  private _renderPageCallback: RenderPageCallback = (...args) => {
    this._pageRenderListeners.forEach(listener => listener(...args));
  };

  openSearchUI = () => {
    this.instance?.setViewState(viewState => viewState.set('interactionMode', this.lib!.InteractionMode.SEARCH));
  };

  hideSearchUI = () => {
    if (this.instance?.viewState.interactionMode === this.lib?.InteractionMode.SEARCH) {
      this.instance?.setViewState(viewState => viewState.set('interactionMode', null));
    }
  };

  unload() {
    this._pageRenderListeners = [];
    this._instanceCleanUpFunctions.forEach(fn => fn());
    this.lib?.unload(this.instance);
    this._onChange(null);
    this._instanceCleanUpFunctions = [];
    this._thumbnailCache = null;
  }

  private _onChange(state: PSPDFKitServiceState | null) {
    this._state = state;
    if (state) {
      this._instanceChangedListeners.forEach(listener => listener(state));
    }
  }

  get instance(): Instance | null {
    return this._state?.instance ?? null;
  }

  get isLoaded() {
    return this._state !== null;
  }

  get lib(): PSPDFKitServiceState['lib'] | null {
    return this._state?.lib ?? null;
  }

  get state(): PSPDFKitServiceState | null {
    return this._state;
  }

  addInstanceChangedListener(listener: PSPDFKitServiceListener) {
    this._instanceChangedListeners.push(listener);
    return () => this.removeAddInstanceChangedListener(listener);
  }

  removeAddInstanceChangedListener(listener: PSPDFKitServiceListener) {
    this._instanceChangedListeners.splice(this._instanceChangedListeners.indexOf(listener), 1);
  }

  get containerElement(): HTMLElement {
    const element = document.querySelector('.PSPDFKit-Container') as HTMLElement;
    return element;
  }

  // Will return null if `pageIndex` is too far from the current page index, in which PSPDFKit hasn't even considered loading it yet.
  getPageElementSync = (pageIndex: number) => {
    if (!this.containerElement) return null;
    const shadowRoot = this.containerElement.shadowRoot;
    return shadowRoot?.querySelector(`[data-page-index="${pageIndex}"][data-page-is-loaded]`);
  };

  pageElementToContentLayerElement = (pageElement: Element) => {
    const contentLayerElement = pageElement.querySelector('.PSPDFKit-Content-Layer');
    if (contentLayerElement) {
      return contentLayerElement;
    }
    return pageElement;
  };

  getPageContainerForInsertingCustomOverlaySync = (pageIndex: number) => {
    const pageElement = this.getPageElementSync(pageIndex);
    if (!pageElement) {
      return null;
    }
    return this.pageElementToContentLayerElement(pageElement);
  };

  isPageLoaded = (pageIndex: number) => {
    const pageElement = this.getPageElementSync(pageIndex);
    if (!pageElement) {
      return false;
    }
    const isLoaded = pageElement.getAttribute('data-page-is-loaded') === 'true';
    if (!isLoaded) return false;

    const hasTextLine = pageElement.querySelector('.PSPDFKit-Content-Layer') !== null;
    return hasTextLine;
  };

  addPageVisibilityListener = (pageIndex: number, callback: PageVisibilityChangedCallback) => {
    const pageRenderedListener = () => {
      const visible = this.isPageLoaded(pageIndex);
      callback(visible);
    };
    const cleanUp = this.addPageRenderedListener(pageRenderedListener);
    pageRenderedListener();
    return () => {
      cleanUp?.();
    };
  };

  addPageIndexListener = (listener: (pageIndex: number) => void) => {
    const instance = this.instance;
    if (!instance) {
      logError(new Error('PSPDFKitFacade.addPageIndexListener() error: PSPDFKit instance not found'));
      return;
    }
    instance.addEventListener('viewState.currentPageIndex.change', listener);
    const pageIndexListener = () => instance.removeEventListener('viewState.currentPageIndex.change', listener);

    this._instanceCleanUpFunctions.push(pageIndexListener);

    return () => {
      pageIndexListener();
      this._instanceCleanUpFunctions = this._instanceCleanUpFunctions.filter(fn => fn !== pageIndexListener);
    };
  };

  addZoomListener = (listener: (zoom: number) => void) => {
    const instance = this.instance;
    if (!instance) {
      logError(new Error('PSPDFKitFacade.addZoomListener() error: PSPDFKit instance not found'));
      return;
    }
    instance.addEventListener('viewState.zoom.change', listener);
    const cleanUpZoomListener = () => instance.removeEventListener('viewState.zoom.change', listener);

    this._instanceCleanUpFunctions.push(cleanUpZoomListener);

    return () => {
      cleanUpZoomListener();
      this._instanceCleanUpFunctions = this._instanceCleanUpFunctions.filter(fn => fn !== cleanUpZoomListener);
    };
  };

  addIsSearchActiveListener = (listener: (isSearchActive: boolean) => void) => {
    const instance = this.instance;
    if (!instance) {
      logError(new Error('PSPDFKitFacade.addIsSearchActiveListener() error: PSPDFKit instance not found'));
      return;
    }
    const viewStateListener = (current: ViewState, prev: ViewState) => {
      if (current?.interactionMode === prev?.interactionMode) {
        return;
      }
      listener(current.interactionMode === this.lib!.InteractionMode.SEARCH);
    };
    instance.addEventListener('viewState.change', viewStateListener);

    const cleanUpIsSearchActiveListener = () => instance.removeEventListener('viewState.change', viewStateListener);

    this._instanceCleanUpFunctions.push(cleanUpIsSearchActiveListener);

    return () => {
      cleanUpIsSearchActiveListener();
      this._instanceCleanUpFunctions = this._instanceCleanUpFunctions.filter(fn => fn !== cleanUpIsSearchActiveListener);
    };
  };

  addPageRenderedListener = (listener: RenderPageCallback) => {
    const instance = this.instance;
    if (!instance) {
      logError(new Error('PSPDFKitFacade.addPageRenderedListener() error: PSPDFKit instance not found'));
      return;
    }

    this._pageRenderListeners.push(listener);

    return () => {
      this._pageRenderListeners = this._pageRenderListeners.filter(fn => fn !== listener);
    };
  };

  getCurrentPageIndex = () => {
    return this.instance?.viewState.currentPageIndex;
  };

  addCustomOverlayItem = ({ id, pageIndex, left, top, node }: { id: string; node: Node; pageIndex: number; left: number; top: number }) => {
    const PSPDFKit = this.lib!;
    const instance = this.instance!;

    const item = new PSPDFKit.CustomOverlayItem({
      id,
      pageIndex,
      node,
      position: new PSPDFKit.Geometry.Point({
        x: left,
        y: top
      })
    });

    instance.setCustomOverlayItem(item);
  };

  goToPage = (pageIndex: number) => {
    const instance = this.instance;
    if (!instance) {
      throw new Error('PSPDFKit instance not found');
    }
    const newState = instance.viewState.set('currentPageIndex', pageIndex);
    instance.setViewState(newState);
  };

  setZoom = (zoomPercentage: number) => {
    const instance = this.instance;
    if (!instance) {
      throw new Error('PSPDFKit instance not found');
    }
    instance.setViewState(state => state.set('zoom', zoomPercentage / 100));
  };

  getThumbnail = async ({ height }: { height: number }, pageIndex: number) => {
    const instance = this.instance;
    if (!instance) {
      throw new Error('PSPDFKit instance not found');
    }

    const cacheKey = `${pageIndex}-${height}`;
    if (this._thumbnailCache?.has(cacheKey)) {
      return this._thumbnailCache.get(cacheKey)!;
    }

    const result = await instance.renderPageAsImageURL({ height }, pageIndex);
    this._thumbnailCache?.set(cacheKey, result);
    return result;
  };

  getTotalPageCount = () => {
    const instance = this.instance;
    if (!instance) {
      throw new Error('PSPDFKit instance not found');
    }

    return instance.totalPageCount;
  };

  getDocumentOutline = () => {
    const instance = this.instance;
    if (!instance) {
      throw new Error('PSPDFKit instance not found');
    }

    return instance.getDocumentOutline();
  };
}
