import { useEffect, useState } from 'react';

import { IConfigCatClient } from 'configcat-common';
import * as configcat from 'configcat-js';
import { ALL_FEATURE_DEFINITIONS, FeatureNameEnum } from 'constants/featureDefinitions';
import { logEvent, updateSessionAttributes } from 'lib/observability';
import { logSegmentEvent } from 'utils/analytics';
import { browserNameAndVersion } from 'utils/browser';
import { getLocalPreference, updateLocalPreferences } from 'utils/preferences';

import * as libFeatureFlag from '@speechifyinc/lib-feature-flag';

import pkg from '../../package.json';

interface IFeatureFlagStatus {
  error?: $TSFixMe;
  isLoading: boolean;
  variant?: string | boolean;
}

interface IConfigcatCustom {
  [key: string]: string;
}

// ESLint: Don't use `{}` as a type
// eslint-disable-next-line @typescript-eslint/ban-types
let featureFlagsPromise: (custom: IConfigcatCustom) => Promise<{}>;
let configCatClient: IConfigCatClient | undefined = undefined;

export { FeatureNameEnum };

const BASE_CONFIGCAT_CUSTOM = {
  // this is to supress warning since chromeExtensionVersion is not available on Web App
  chromeExtensionVersion: '0.0.0',
  webAppVersion: pkg.version
};

async function loadIncognitoFeatureFlag(custom: IConfigcatCustom) {
  if (!process.env.NEXT_PUBLIC_CONFIG_CAT_API_KEY) return;

  if (configCatClient === undefined) {
    configCatClient = configcat.getClient(process.env.NEXT_PUBLIC_CONFIG_CAT_API_KEY, configcat.PollingMode.LazyLoad, {
      cacheTimeToLiveSeconds: 60 * 60 * 6
    });
  }

  const settings = await configCatClient.getAllValuesAsync({
    custom: {
      ...BASE_CONFIGCAT_CUSTOM,
      ...custom
    },
    identifier: 'incognito'
  });

  const featureFlags = settings.reduce((map, setting) => {
    map[setting.settingKey] = setting.settingValue;
    return map;
  }, {} as $TSFixMe);
  // configcat API doesn't throw an error when there is an issue,
  // it just returns an empty object. So if object is empty, attempt to pull
  // data from the localStorage cache.
  if (!featureFlags || Object.keys(featureFlags).length === 0) {
    const cachedFeatureFlags = getFeatureFlagsFromLocalStorage();

    if (cachedFeatureFlags) {
      return cachedFeatureFlags;
    }

    throw new Error('Unable to load data from configcat');
  } else {
    setFeatureFlagsInLocalStorage(featureFlags);
    return featureFlags;
  }
}

// ESLint: Don't use `{}` as a type
// eslint-disable-next-line @typescript-eslint/ban-types
export function useIncognitoFeatureFlag(feature: string, metadata?: {}) {
  const [status, setStatus] = useState<IFeatureFlagStatus>({ isLoading: true });

  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type '{} | undefined' is not assignabl... Remove this comment to see the full error message
    loadIncognitoFeatureFlag(metadata)
      .then((features: $TSFixMe) => {
        const variant = features[feature];
        setStatus({ isLoading: false, variant });
      })
      .catch((error: $TSFixMe) => {
        setStatus({ isLoading: false, error });
      });
    // ESLint: React Hook useEffect has a missing dependency: 'metadata'. Either include it or remove the dependency array. Outer scope values like 'featureFlagsPromise' aren't valid dependencies because mutating them doesn't re-render the component. & React Hook useEffect has a complex expression in the dependency array. Extract it to a separate variable so it can be statically checked.
    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/exhaustive-deps
  }, [feature, featureFlagsPromise, JSON.stringify(metadata)]);

  return status;
}

export function loadFeatureFlags(uid: string) {
  if (!process.env.NEXT_PUBLIC_CONFIG_CAT_API_KEY) return;

  if (configCatClient === undefined) {
    configCatClient = configcat.getClient(process.env.NEXT_PUBLIC_CONFIG_CAT_API_KEY);
  }

  featureFlagsPromise = (custom: IConfigcatCustom) =>
    configCatClient!
      .getAllValuesAsync({
        custom: {
          ...BASE_CONFIGCAT_CUSTOM,
          ...custom
        },
        identifier: uid || 'incognito'
      })
      .then(settings => {
        const featureFlags = settings.reduce((map, setting) => {
          map[setting.settingKey] = setting.settingValue;
          return map;
        }, {} as $TSFixMe);
        // configcat API doesn't throw an error when there is an issue,
        // it just returns an empty object. So if object is empty, attempt to pull
        // data from the localStorage cache.
        if (!featureFlags || Object.keys(featureFlags).length === 0) {
          const cachedFeatureFlags = getFeatureFlagsFromLocalStorage();

          if (cachedFeatureFlags) {
            return cachedFeatureFlags;
          }

          throw new Error('Unable to load data from configcat');
        } else {
          setFeatureFlagsInLocalStorage(featureFlags);
          return featureFlags;
        }
      });

  featureFlagsPromise({}).then((flags: $TSFixMe) => {
    if (!flags) return;

    const enumValuesMap = Object.keys(FeatureNameEnum).reduce((values, key) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      values[FeatureNameEnum[key]] = key;
      return values;
    }, {});

    const features = Object.keys(flags)
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      .filter(key => enumValuesMap[key])
      .reduce((map, key) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        map[key] = flags[key];
        return map;
      }, {});

    updateSessionAttributes(features);
  });
}

function getFeatureFlagsFromLocalStorage() {
  return getLocalPreference('feature_flags') as $TSFixMe;
}

// ESLint: Don't use `{}` as a type
// eslint-disable-next-line @typescript-eslint/ban-types
function setFeatureFlagsInLocalStorage(featureFlags: {}) {
  updateLocalPreferences({ feature_flags: JSON.stringify(featureFlags) });
}

/**
 * @deprecated please use useFeatureVariant instead
 */
// ESLint: Don't use `{}` as a type
// eslint-disable-next-line @typescript-eslint/ban-types
export function useFeatureFlag(feature: FeatureNameEnum, metadata?: {}) {
  const [status, setStatus] = useState<IFeatureFlagStatus>({ isLoading: true });

  useEffect(() => {
    let shouldUpdate = true;

    if (!featureFlagsPromise) return;
    // @ts-expect-error TS(2345): Argument of type '{} | undefined' is not assignabl... Remove this comment to see the full error message
    featureFlagsPromise(metadata)
      ?.then((features: $TSFixMe) => {
        if (shouldUpdate) {
          const variant = features[feature];
          setStatus({ isLoading: false, variant });
        }
      })
      .catch((error: $TSFixMe) => {
        if (shouldUpdate) {
          setStatus({ isLoading: false, error });
        }
      });

    return () => {
      shouldUpdate = false;
    };
    // ESLint: React Hook useEffect has a missing dependency: 'metadata'. Either include it or remove the dependency array. Outer scope values like 'featureFlagsPromise' aren't valid dependencies because mutating them doesn't re-render the component. & React Hook useEffect has a complex expression in the dependency array. Extract it to a separate variable so it can be statically checked.
    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/exhaustive-deps
  }, [feature, featureFlagsPromise, JSON.stringify(metadata)]);

  return status;
}

const waitUntil = async (condition: $TSFixMe) => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (condition()) {
        clearInterval(interval);
        resolve(Boolean(condition));
      }
    }, 32);
  });
};

/**
 * @deprecated please use getFeatureVariant instead
 * @param feature
 * @param metadata
 * @returns
 */
// ESLint: Don't use `{}` as a type
// eslint-disable-next-line @typescript-eslint/ban-types
export async function getFeatureFlag(feature: FeatureNameEnum, metadata?: {}) {
  await waitUntil(() => !!featureFlagsPromise);
  // @ts-expect-error TS(2345): Argument of type '{} | undefined' is not assignabl... Remove this comment to see the full error message
  return featureFlagsPromise(metadata)?.then(features => features[feature]);
}

/**
 * Should be called when a feature flag is "experienced".
   This logs the AB_TEST_VARIANT_ASSIGNED event when a feature
   variant is assigned and experienced the first time, and if the
   user is assigned a new variant
 */
export function logVariant(featureName: string, variant: string | boolean | undefined) {
  const variantsAssigned = JSON.parse(localStorage.getItem('variants_assigned') as $TSFixMe) || {};

  variantsAssigned[featureName] = variantsAssigned[featureName] || {};

  if (variantsAssigned[featureName] !== variant) {
    localStorage.setItem('variants_assigned', JSON.stringify({ ...variantsAssigned, ...{ [featureName]: variant } }));

    logSegmentEvent('extension_usage_ab_test_variant_assigned', {
      variant,
      testName: featureName
    });

    logSegmentEvent('extension_usage_ab_test_variant_assigned_new', {
      variant,
      testName: featureName
    });
  }
}

const featureLibrary = libFeatureFlag.createFeatureLibrary(ALL_FEATURE_DEFINITIONS, {
  client: 'web',
  getBrowserMetadata: () => {
    if (typeof window === 'undefined')
      return {
        browserName: 'Server',
        browserVersion: '0'
      };
    const [browserName, browserVersion] = browserNameAndVersion('/').split('/');
    const { hostname, href } = window.location;
    return {
      browserName,
      browserVersion,
      hostname,
      href
    };
  },
  logVariant,
  loadFeatureFlags: async () => {
    await waitUntil(() => !!featureFlagsPromise);
    return featureFlagsPromise({});
  },
  logFaroEvent: logEvent
});

export const useFeatureVariant = featureLibrary.useFeatureVariant;
export const getFeatureVariant = featureLibrary.getFeatureVariant;
export const isFeatureVariantReady = featureLibrary.isFeatureVariantReady;

export const getFeatureVariantForNewUsers = featureLibrary.getFeatureVariantForNewUsers;
export const useFeatureVariantForNewUsers = featureLibrary.useFeatureVariantForNewUsers;
