import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import type { PlaybackInfo, VoiceInfo } from 'modules/sdk/lib';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';

import { setVoice } from '../stores/actions/setVoice';
import { getVoiceState } from '../stores/selectors/getVoiceState';
import { useVoiceStore } from '../stores/voicesStore';
import { VoiceItemUIProp } from '../types';

export const mapVoiceInfoToVoiceItemUI = ({
  voiceInfo,
  playbackInfo,
  isPreviewPlaying,
  latestPlayingVoice,
  isPremiumUser,
  hasHdWords
}: {
  voiceInfo: VoiceInfo;
  playbackInfo?: PlaybackInfo;
  latestPlayingVoice?: VoiceInfo;
  isPreviewPlaying?: boolean;
  isPremiumUser?: boolean;
  hasHdWords?: boolean;
}): VoiceItemUIProp => {
  playbackInfo ??= usePlaybackStore.getState().currentPlaybackInfo;
  latestPlayingVoice ??= useVoiceStore.getState().lastPlayingVoice;
  isPremiumUser ??= subscriptionStoreSelectors.getIsPremium();
  hasHdWords ??= subscriptionStoreSelectors.getHasHdWords();
  isPreviewPlaying ??= useVoiceStore.getState().isPreviewPlaying;

  const state = getVoiceState({ voiceInfo: voiceInfo, latestPlayingVoice, isPremiumUser, hasHdWords });

  return {
    ...voiceInfo,
    ...state,
    previewPlaying: isPreviewPlaying && state.state === 'active',
    onClick: () => {
      setVoice(voiceInfo, {
        currentPlaybackInfo: playbackInfo
      });
    }
  };
};
