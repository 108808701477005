import { useMemo } from 'react';

import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';
import { useSubscriptionStore } from 'modules/subscription/stores/subscriptionStore';

import { useVoiceStore } from '../stores/voicesStore';
import { VoiceItemUIProp } from '../types';
import { mapVoiceInfoToVoiceItemUI } from '../utils/mapVoiceInfoToVoiceItemUI';
import { groupVoicesByLanguage } from '../utils/utils';

export const useVoiceByLanguage = (searchString: string | null) => {
  const voiceState = useVoiceStore();
  const playbackInfo = usePlaybackStore(s => s.currentPlaybackInfo);
  const hasHdWords = useSubscriptionStore(subscriptionStoreSelectors.getHasHdWords);

  const voiceByLanguage = useMemo(() => {
    const { personalVoices, allVoices } = voiceState;
    const voiceSpecsByLanguage = groupVoicesByLanguage([...personalVoices, ...allVoices]);

    const voicesByLanguage: Record<string, VoiceItemUIProp[]> = {};
    for (const [languageCode, voices] of Object.entries(voiceSpecsByLanguage)) {
      voicesByLanguage[languageCode] = voices
        .filter(v => {
          if (!searchString) return true;
          if (v.displayName.toLowerCase().includes(searchString.toLowerCase())) {
            return true;
          }
          return false;
        })
        .map(v => mapVoiceInfoToVoiceItemUI({ voiceInfo: v, playbackInfo, hasHdWords, isPreviewPlaying: voiceState.isPreviewPlaying }));
    }
    return voicesByLanguage;
  }, [voiceState, playbackInfo, searchString, hasHdWords]);

  return voiceByLanguage;
};
