import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector, useStore as useReduxStore } from 'react-redux';
import { persistStore } from 'redux-persist';

import createIdbStorage from '@piotr-cz/redux-persist-idb-storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import askAiV2Reducer from './askAiV2';
import authReducer from './auth';
import authMiddleware from './auth/middleware';
import { REDUX_IDB_DB_NAME, REDUX_IDB_OBJECT_STORE_NAME } from './constants';
import crossLoginReducer from './crossLogin';
import darkModeReducer from './darkMode';
import gamificationReducer from './gamification';
import gamificationMiddleware from './gamification/middleware';
import importReducer from './import';
import integrationReducer from './integration';
import itemReducer from './item';
import libraryReducer from './library';
import libraryMiddleware from './library/middleware';
import mainPageReducer from './mainPage';
import onboardingReducer from './onboarding';
import personalVoicesReducer from './personalVoices';
import personalVoicesMiddleware from './personalVoices/middleware';
import preferencesReducer from './preferences';
import readerReducer from './reader';
import routerReducer from './router';
import sessionReducer from './session';
import sidebarReducer from './sidebar';
import teamReducer from './team';
import teamMiddleware from './team/middleware';
import toastReducer from './toast';
import usageReducer from './usage';
import voiceSelectionReducer from './voiceSelection';

const reducers = {
  app: appReducer,
  askAiV2: askAiV2Reducer,
  auth: authReducer,
  crossLogin: crossLoginReducer,
  darkMode: darkModeReducer,
  gamification: gamificationReducer,
  import: importReducer,
  integration: integrationReducer,
  item: itemReducer,
  library: libraryReducer,
  mainPage: mainPageReducer,
  onboarding: onboardingReducer,
  personalVoices: personalVoicesReducer,
  preferences: preferencesReducer,
  reader: readerReducer,
  router: routerReducer,
  session: sessionReducer,
  sidebar: sidebarReducer,
  team: teamReducer,
  toast: toastReducer,
  usage: usageReducer,
  voiceSelection: voiceSelectionReducer
} as const;

const rootReducer = combineReducers(reducers);

const actionSanitizer = (action: $TSFixMe) =>
  action.type === 'library/downloadPage/fulfilled' && action.payload ? { ...action, payload: { ...action.payload, html: '...', textContent: '...' } } : action;

const stateSanitizer = (state: $TSFixMe): $TSFixMe => {
  if (state.library && state.library.pages) return { ...state, library: { ...state.library, pages: {} } };
  return state;
};

const isClient = typeof window !== 'undefined';

let storeState: typeof rootReducer;

const PERSISTED_STATE_KEYS = ['app', 'auth', 'darkMode', 'gamification', 'library', 'usage', 'onboarding', 'askAiV2', 'personalVoices', 'integration'] as const;

if (isClient) {
  // ESLint: Require statement not part of import statement
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { persistReducer } = require('redux-persist');
  // ESLint: Require statement not part of import statement
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const storage = require('redux-persist/lib/storage').default;

  const persistConfig = {
    key: 'root',
    storage: globalThis.indexedDB ? createIdbStorage({ name: REDUX_IDB_DB_NAME, storeName: REDUX_IDB_OBJECT_STORE_NAME }) : storage,
    whitelist: PERSISTED_STATE_KEYS,
    blacklist: ['crossLogin', 'sidebar'] as (keyof typeof reducers)[]
  };

  storeState = persistReducer(persistConfig, rootReducer);
} else {
  storeState = rootReducer;
}

const store = configureStore({
  reducer: storeState,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(authMiddleware)
      .concat(gamificationMiddleware)
      .concat(libraryMiddleware)
      .concat(personalVoicesMiddleware)
      .concat(teamMiddleware),
  devTools: { actionSanitizer, stateSanitizer }
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useStore: () => typeof store = useReduxStore;
export const useDispatch: () => AppDispatch = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export interface RootState extends ReturnType<typeof rootReducer> {}

type PersistedStateKey = (typeof PERSISTED_STATE_KEYS)[number];
export type SpeechifyPersistedStoreState = Pick<RootState, PersistedStateKey>;

export { persistor, store };
