import { logUpgradeButtonAnalyticsEvent } from 'modules/analytics/impl/upgradeButtonAnalytics';

import { UpgradeSource } from './UpgradeSource';

export const getUpgradeURL = (source: UpgradeSource): string => `${process.env.NEXT_PUBLIC_SAME_DOMAIN_ONBOARDING_URL}/onboarding/sso/?source=${source}`;

export const onUpgradeButtonClick = (source: UpgradeSource): void => {
  window.open(getUpgradeURL(source), '_blank');
  logUpgradeButtonAnalyticsEvent(source);
};
