import { ErrorSource } from 'constants/errors';
import { WebAppListeningExperienceOverhaulVariant } from 'constants/featureDefinitions';
import { logError } from 'lib/observability';
import { PSPDFKitFacade } from 'lib/pdf/pspdfkit';
import { createNonPersistentStore } from 'lib/zustand/store';
import { logAnalyticsEventOnBackwardClick, logAnalyticsEventOnForwardClick, logAnalyticsEventOnPlay } from 'modules/analytics/impl/playbackAnalytics';
import type { Virtualizer } from 'modules/listening/features/reader/components/classic/ClassicReaderV2';
import { listeningSettingsStoreActions } from 'modules/listening/features/settings/settingsStore';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';
import { ListenableContent, PlaybackInfo, SDKContentBundlerOptionsFacade } from 'modules/sdk/lib';
import { PDFListenableContent } from 'modules/sdk/lib/facade/listenableContent/types';
import { listenableContentErrorContext } from 'modules/sdk/lib/facade/listenableContent/utils';
import { ListeningDependencies, ListeningDependenciesFactory } from 'modules/sdk/listeningDependencies';
import { tryQueueMobileUpsellBanner } from 'modules/sideBanner/stores/continuePhoneActions';
import { fetchInitialSpeedInWPM, speedStoreActions } from 'modules/speed/store/speedStore';
import { subscriptionStoreActions } from 'modules/subscription/stores/actions';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';
import { voiceStoreActions } from 'modules/voices/stores/actions';
import { checkAutoplayIsAvailable, shouldAutoplay } from 'utils/audio';

type BasePlaybackState = {
  latestPlaybackState: undefined;
  currentListenableContent: undefined;
  currentPlaybackInfo: undefined;
  currentOverlayInfo: undefined;
  contentBundlerOptionsFacade: undefined;
  listeningDependencies: undefined;
};

type InitialState = BasePlaybackState & {
  isReady: false;
};

type LoadingState = BasePlaybackState & {
  isReady: false;
};

export type PlaybackStoreReadyState = {
  isReady: true;

  currentListenableContent: ListenableContent;
  listeningDependencies: ListeningDependencies;
  currentPlaybackInfo: ListeningDependencies['playbackInfo'];
  currentOverlayInfo: ListeningDependencies['overlayInfo'];

  latestPlaybackState: PlaybackInfo['latestState'];
  contentBundlerOptionsFacade: SDKContentBundlerOptionsFacade;
};

export type PlaybackStoreState = InitialState | LoadingState | PlaybackStoreReadyState;

export const usePlaybackStore = createNonPersistentStore<PlaybackStoreState>(() => {
  return {
    isReady: false,
    currentPlaybackInfo: undefined,
    currentOverlayInfo: undefined,
    latestPlaybackState: undefined,
    currentListenableContent: undefined,
    contentBundlerOptionsFacade: undefined,
    listeningDependencies: undefined
  };
});

usePlaybackStore.subscribe((state, prevState) => {
  if (state.currentPlaybackInfo !== prevState.currentPlaybackInfo) {
    if (prevState.currentPlaybackInfo) {
      prevState.currentPlaybackInfo.controls.pause();
      prevState.currentPlaybackInfo.destroy();
    }

    if (state.currentPlaybackInfo) {
      // note: it's safe to not clean this up because the listener will be removed when the PlaybackInfo is destroyed, see PlaybackInfo.destroy() implementation
      state.currentPlaybackInfo.addStateListener(playbackState => {
        usePlaybackStore.setState(() => ({
          latestPlaybackState: playbackState
        }));
      });
    }
  }
});

const _maybeStartAutoPlay = async (listeningDependencies: ListeningDependencies) => {
  const autoplay = shouldAutoplay();
  if (autoplay) {
    const isPlaying = listeningDependencies.playbackInfo.latestState.isPlaying;
    if (!isPlaying) {
      const isAutoplayAvailable = await checkAutoplayIsAvailable();
      if (isAutoplayAvailable) {
        resume();
        logAnalyticsEventOnPlay('auto play');
      }
    }
  }
};

const init = async (listenableContent: ListenableContent, listeningDependencies: ListeningDependencies) => {
  usePlaybackStore.setState(() => ({
    currentListenableContent: listenableContent,
    listeningDependencies,
    currentPlaybackInfo: listeningDependencies.playbackInfo,
    currentOverlayInfo: listeningDependencies.overlayInfo,
    latestPlaybackState: listeningDependencies.playbackInfo.latestState,
    contentBundlerOptionsFacade: listeningDependencies.contentBundlerOptionsFacade
  }));

  await speedStoreActions.initializeSpeedStore();
  await voiceStoreActions.initializeVoiceStore(listeningDependencies);
  await listeningSettingsStoreActions.initializeListeningSettingsStore(listeningDependencies);
  subscriptionStoreActions.startWordTracker(listeningDependencies);

  usePlaybackStore.setState(() => ({
    isReady: true
  }));

  // run on the next tick once the UI is updated
  setTimeout(() => {
    _maybeStartAutoPlay(listeningDependencies);
  }, 1);
  // mobile app upsell banner
  tryQueueMobileUpsellBanner();
};

const initializePdfExperience = async (listenableContent: PDFListenableContent, pdfContainer: HTMLElement): Promise<void> => {
  const currentListenableContent = usePlaybackStore.getState().currentListenableContent;
  if (currentListenableContent === listenableContent) {
    return;
  }
  try {
    usePlaybackStore.setState(() => ({
      isReady: false
    }));

    PSPDFKitFacade.singleton.load({
      container: pdfContainer,
      listenableContent
    });

    profilingStoreActions.startMeasurement(MeasurementKey.sdkSetup);
    const [initialVoice, initialWPM] = await Promise.all([voiceStoreActions.fetchInitialVoice(), fetchInitialSpeedInWPM()]);

    const listeningDependencies = await ListeningDependenciesFactory.create({
      listenableContent,
      initialVoice,
      initialWPM,
      shouldEnableOCR: subscriptionStoreSelectors.getIsPremium()
    });

    await init(listenableContent, listeningDependencies);
    profilingStoreActions.endMeasurement(MeasurementKey.instantListeningBookReaderReady);
    profilingStoreActions.measureFromNavigationStart(MeasurementKey.bookReaderReady, {
      listeningOverhaulVariant: WebAppListeningExperienceOverhaulVariant.ENABLED
    });

    // update settings
    listeningSettingsStoreActions.onZoomChange((PSPDFKitFacade.singleton.instance?.currentZoomLevel ?? 1) * 100);
  } catch (errorMessage) {
    const error = errorMessage instanceof Error ? errorMessage : new Error(`${errorMessage}`);
    logError(error, ErrorSource.PLAYBACK, {
      context: listenableContentErrorContext(listenableContent)
    });

    usePlaybackStore.setState(() => ({
      isReady: false
    }));

    throw error;
  }
};

const initializeClassicExperience = async ({
  listenableContent,
  scrollerElement,
  overlayElement,
  virtualizer
}: {
  listenableContent: ListenableContent;
  scrollerElement: HTMLElement;
  overlayElement: HTMLElement;
  virtualizer: Virtualizer;
}): Promise<ListeningDependencies> => {
  const currentState = usePlaybackStore.getState();
  if (currentState.currentListenableContent === listenableContent) {
    return currentState.listeningDependencies;
  }
  try {
    const [initialVoice, initialWPM] = await Promise.all([voiceStoreActions.fetchInitialVoice(), fetchInitialSpeedInWPM()]);
    const listeningDependencies = await ListeningDependenciesFactory.create({
      listenableContent,
      initialVoice,
      initialWPM,
      shouldEnableOCR: subscriptionStoreSelectors.getIsPremium(),
      scrollerElement: scrollerElement,
      overlayElement: overlayElement,
      virtualizer
    });

    await init(listenableContent, listeningDependencies);
    profilingStoreActions.endMeasurement(MeasurementKey.instantListeningClassicReaderReady);
    profilingStoreActions.measureFromNavigationStart(MeasurementKey.classicReaderReady, {
      listeningOverhaulVariant: WebAppListeningExperienceOverhaulVariant.ENABLED
    });
    return listeningDependencies;
  } catch (errorMessage) {
    const error = errorMessage instanceof Error ? errorMessage : new Error(`${errorMessage}`);
    logError(error, ErrorSource.PLAYBACK, {
      context: listenableContentErrorContext(listenableContent)
    });
    usePlaybackStore.setState(() => ({
      isReady: false
    }));
    throw error;
  }
};

const _withShouldSwitchToFreeCheck = (fn: (playbackInfo: PlaybackInfo) => void) => {
  return (playbackInfo?: PlaybackInfo) => {
    playbackInfo ??= usePlaybackStore.getState().currentPlaybackInfo;
    if (!playbackInfo) {
      return;
    }

    if (subscriptionStoreSelectors.getShouldSwitchToFree()) {
      subscriptionStoreActions.switchToFree();
    }

    fn(playbackInfo);
  };
};

const pressPlayPause = _withShouldSwitchToFreeCheck((playbackInfo: PlaybackInfo) => {
  playbackInfo.controls.pressPlayPause();
});

const pause = _withShouldSwitchToFreeCheck((playbackInfo: PlaybackInfo) => {
  playbackInfo.pause();
});

const resume = _withShouldSwitchToFreeCheck((playbackInfo: PlaybackInfo) => {
  playbackInfo.resume();
});

const onForwardClick = (playbackInfo?: PlaybackInfo) => {
  playbackInfo ??= usePlaybackStore.getState().currentPlaybackInfo;
  if (!playbackInfo) return;

  playbackInfo.controls.skipForwards();
  logAnalyticsEventOnForwardClick();
};

const onBackwardClick = (playbackInfo?: PlaybackInfo) => {
  playbackInfo ??= usePlaybackStore.getState().currentPlaybackInfo;
  if (!playbackInfo) return;

  playbackInfo.controls.skipBackwards();
  logAnalyticsEventOnBackwardClick();
};

export const playbackStoreActions = {
  initializeClassicExperience,
  initializePdfExperience,
  onBackwardClick,
  onForwardClick,
  pressPlayPause,
  pause,
  resume
};
