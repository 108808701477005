import type { Virtualizer } from 'modules/listening/features/reader/components/classic/ClassicReaderV2';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';
import { BaseDestructible } from 'utils/destructible';
import { Nullable } from 'utils/types';

import type { ReadingBundle as SDKReadingBundle } from '@speechifyinc/multiplatform-sdk';

import { getSDK, ListenableContent, PDFOverlayInfo, PlaybackInfo, SDKContentBundlerOptionsFacade, VoiceInfo } from './lib';
import { ClassicOverlayInfo } from './lib/facade/overlay/ClassicOverlayInfo';

type BaseListeningRequirements = {
  listenableContent: ListenableContent;
  initialVoice: Nullable<VoiceInfo>;
  initialWPM: number;
  shouldEnableOCR: boolean;
};

type PDFListeningRequirements = BaseListeningRequirements;

type ClassicListeningRequirements = BaseListeningRequirements & {
  overlayElement: HTMLElement;
  scrollerElement: HTMLElement;
  virtualizer: Virtualizer;
};

export type ListeningRequirements = PDFListeningRequirements | ClassicListeningRequirements;

export class ListeningDependencies extends BaseDestructible {
  constructor(
    private readonly bundle: SDKReadingBundle,
    public readonly playbackInfo: PlaybackInfo,
    public readonly overlayInfo: PDFOverlayInfo | ClassicOverlayInfo,
    public readonly contentBundlerOptionsFacade: SDKContentBundlerOptionsFacade
  ) {
    super();
  }

  destroy(): void {
    this.playbackInfo.destroy();
    this.overlayInfo.destroy();
    this.bundle.destroy();
  }
}

export class ListeningDependenciesFactory {
  public static async create(requirements: ListeningRequirements): Promise<ListeningDependencies> {
    const { sdk, bundle: bundleFacade, voice: voiceFacade } = await getSDK();

    const allVoices = await profilingStoreActions.measureAction(() => voiceFacade.getAllVoices(), MeasurementKey.sdkGetAllVoices);
    const initialVoice = voiceFacade.getVoiceSpec(requirements.initialVoice);

    profilingStoreActions.startMeasurement(MeasurementKey.sdkBundleCreation);
    const { readingBundle: bundle, contentBundlerOptions } = await bundleFacade.createBundles({
      ...requirements,
      initialVoice,
      allVoices
    });
    profilingStoreActions.endMeasurement(MeasurementKey.sdkBundleCreation);
    profilingStoreActions.endMeasurement(MeasurementKey.sdkSetup);

    const playbackInfo = new PlaybackInfo(sdk, bundle, initialVoice);

    const createOverlayInfo = async () => {
      if (requirements.listenableContent.isPDF()) {
        const overlayInfo = new PDFOverlayInfo(sdk, bundle, playbackInfo);
        await overlayInfo.addRenderedContentToOverlayProvider();
        return overlayInfo;
      }
      const classicRequirements = requirements as ClassicListeningRequirements;
      const classicOverlayInfo = new ClassicOverlayInfo(
        sdk,
        bundle,
        playbackInfo,
        classicRequirements.scrollerElement,
        classicRequirements.overlayElement,
        classicRequirements.virtualizer
      );
      await classicOverlayInfo.addRenderedContentToOverlayProvider();
      return classicOverlayInfo;
    };

    const overlayInfo = await createOverlayInfo();

    return new ListeningDependencies(bundle, playbackInfo, overlayInfo, contentBundlerOptions);
  }
}
