import {
  AbstractLocalSpeechSynthesisAdapter,
  LocalSpeechSynthesisEvent,
  LocalSynthesisUtterance,
  LocalSynthesisVoice,
  VoiceRef
} from '@speechifyinc/multiplatform-sdk/api/adapters/localsynthesis';
import { VoiceGender } from '@speechifyinc/multiplatform-sdk/api/audio';
import { Result, SDKError } from '@speechifyinc/multiplatform-sdk/api/util';

import { callbackFromAsync } from './lib/callbackFromAsync';
import { Callback } from './lib/typeAliases';
import { delay } from './utils/delay';
import { type PromiseWithCompletionController, PromiseWithCompletionControllerUtil } from './utils/promiseWithCompletionController';

export class WebLocalSpeechSynthesisAdapter extends AbstractLocalSpeechSynthesisAdapter {
  getAllVoices = (callback: Callback<LocalSynthesisVoice[]>) => {
    new Promise<SpeechSynthesisVoice[]>(resolve => {
      const voices = speechSynthesis.getVoices();
      if (voices.length > 0) resolve(voices);

      speechSynthesis.onvoiceschanged = () => {
        speechSynthesis.onvoiceschanged = null;
        resolve(speechSynthesis.getVoices());
      };
    })
      .then(voices => voices.filter(voice => !voice.voiceURI.startsWith('Google')).map(toLocalVoice))
      .then(voices => callback(new Result.Success(voices)));
  };

  speak(utterance: LocalSynthesisUtterance, callback: Callback<LocalSpeechSynthesisEvent>): void {
    const utterThis = new SpeechSynthesisUtterance(utterance.text);
    utterThis.voice = utterance.voice.source.get;
    // ESLint: '_' is defined but never used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    utterThis.onstart = _ => callback(new Result.Success(LocalSpeechSynthesisEvent.Started));
    // ESLint: '_' is defined but never used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    utterThis.onpause = _ => {
      callback(new Result.Success(LocalSpeechSynthesisEvent.Paused));
    };
    // ESLint: '_' is defined but never used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    utterThis.onend = _ => callback(new Result.Success(LocalSpeechSynthesisEvent.Ended));
    utterThis.onerror = e => callback(new Result.Failure(new SDKError.OtherMessage(e.error)));
    utterThis.onboundary = ({ charIndex }) => callback(new Result.Success(new LocalSpeechSynthesisEvent.Progressed(charIndex)));
    utterThis.rate = utterance.options.speed;
    utterThis.volume = utterance.options.volume;

    window.speechSynthesis.speak(utterThis);
  }

  pause(): void {
    window.speechSynthesis.pause();
  }

  resume(): void {
    window.speechSynthesis.resume();
  }

  cancel(): void {
    window.speechSynthesis.cancel();
  }
}

export async function getVoices() {
  if (typeof globalThis.speechSynthesis === 'undefined') return [];

  let voices = speechSynthesis.getVoices();
  if (voices.length === 0) {
    let foundSomeVoices = false;
    speechSynthesis.onvoiceschanged = () => {
      speechSynthesis.onvoiceschanged = null;
      foundSomeVoices = true;
      voices = speechSynthesis.getVoices();
    };
    await delay(1000);
    if (!foundSomeVoices) {
      console.log("SpeechSynthesisAdapter Couldn't find any local voices");
      speechSynthesis.onvoiceschanged = null;
    }
  }

  return (
    voices
      // We exclude Google Voices because they don't support word boundary events
      .filter(localVoice => !localVoice.voiceURI.startsWith('Google'))
      .map(v => new LocalSynthesisVoice(v.name, v.name, v.lang, VoiceGender.MALE, new VoiceRef(v)))
  );
}

export class SilentQuickLocalSynthesisForTesting extends AbstractLocalSpeechSynthesisAdapter {
  constructor(delayMs = 1) {
    super();

    (async () => {
      /* eslint-disable no-constant-condition */ /* no brain to rewrite this, while it's just a component for testing */
      while (true) {
        /* eslint-enable no-constant-condition */
        await this.#speechesNotEmptyPromise.promise;

        const speech = this.#speechesQueue[0]!;

        const { utterance, callback } = speech.request;

        const waitIfPaused = async () => {
          if (this.#isPaused) {
            speech.resumedPromise = PromiseWithCompletionControllerUtil.create();
            callback(new Result.Success(LocalSpeechSynthesisEvent.Paused));
            await speech.resumedPromise.promise;
          }
        };

        if (!speech.isCancelled) {
          await waitIfPaused();
          callback(new Result.Success(LocalSpeechSynthesisEvent.Started));

          for (const charIndex of Array(utterance.text.length).keys()) {
            if (charIndex % 20 === 0) {
              // Delaying between less characters seems too slow, even with 1 ms delay (it seems that using the `setTimeout()` adds a good deal of a delay)
              await delay(delayMs);
            }

            if (charIndex % 10 === 0) {
              await waitIfPaused();
              callback(new Result.Success(new LocalSpeechSynthesisEvent.Progressed(charIndex)));
            }

            if (speech.isCancelled) break;
          }
        }

        this.#speechesQueue.shift();
        if (this.#speechesQueue.length === 0) {
          if (!speech.isCancelled) {
            await waitIfPaused();
            callback(new Result.Success(LocalSpeechSynthesisEvent.Ended));
          }
          this.#speechesNotEmptyPromise = PromiseWithCompletionControllerUtil.create();
        }
      }
    })();
  }

  getAllVoices = (callback: Callback<LocalSynthesisVoice[]>) =>
    callbackFromAsync(async () => {
      return await getVoices();
    }, callback);

  #addToQueue(speechRequest: SpeechRequest) {
    this.#speechesQueue.push({
      request: speechRequest,
      isCancelled: false,
      resumedPromise: PromiseWithCompletionControllerUtil.resolve<void>(undefined)
    });

    this.#speechesNotEmptyPromise.completionController.ensureResolved();
  }

  speak = async (utterance: LocalSynthesisUtterance, callback: (p0: Result<LocalSpeechSynthesisEvent>) => void): Promise<void> => {
    if (this.#isPaused)
      this.#isPaused = false; /* This is a workaround for the current strange behavior, where when a `Paused` click gets inserted after `Ended`
      , the pipeline ignores the click (considers player to be 'playing', likely because of the 'transient players pipeline' architecture it gets
       sent to the old player - this may actually reach the user when using the real synthesis), but then a `speak` does get called (also a `cancel` gets called `Ended`, but this seems rather incidental).
      */

    this.#addToQueue({
      callback: callback,
      utterance: utterance
    });
  };

  pause = () => {
    this.#isPaused = true;
  };

  resume = () => {
    this.#isPaused = false;
    for (const speech of this.#speechesQueue) {
      speech.resumedPromise.completionController.ensureResolved(undefined);
    }
  };

  cancel = () => {
    for (const speech of this.#speechesQueue) {
      speech.isCancelled = true;
      /* TODO - figure out why `cancel` is called after every `End` event (this makes us sometimes miss the pause), because of the below,
         but the below seems sensible! (when `cancel` is received, the player should empty itself, including resetting the paused state to not-paused)
      */
      speech.resumedPromise.completionController.ensureResolved(undefined);
    }
  };

  #speechesQueue: Array<{
    request: SpeechRequest;
    isCancelled: boolean;
    resumedPromise: PromiseWithCompletionController<void>;
  }> = [];

  #isPaused = false;

  #speechesNotEmptyPromise = PromiseWithCompletionControllerUtil.create<void>();
}

type SpeechRequest = {
  callback: (p0: Result<LocalSpeechSynthesisEvent>) => void;
  utterance: LocalSynthesisUtterance;
};

function toLocalVoice(v: SpeechSynthesisVoice): LocalSynthesisVoice {
  return new LocalSynthesisVoice(v.voiceURI, v.name, v.lang, VoiceGender.MALE, new VoiceRef(v));
}
