import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { speedStoreActions } from 'modules/speed/store/speedStore';
import { showWordLimitModal } from 'modules/upsells/stores/actions/showWordLimitModal';
import { voiceStoreActions } from 'modules/voices/stores/actions';

export const switchToFree = async () => {
  showWordLimitModal();

  voiceStoreActions.switchToFreeVoice();
  speedStoreActions.switchToFreeSpeed();

  logAnalyticsEvent(AnalyticsEventKey.switchedToFree, {});
};
